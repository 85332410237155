import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from "classnames";
import EditProfileForm from "./edit";
import ProfileEdit from "./profileEdit";
import axios from "axios";
import CopyLink from "../../../components/CommonForBoth/TopbarDropdown/CopyLink";
import BACKEND_DOMAIN, { STORE_FRONT_DOMAIN } from "../../../service";

const WEB_APP_URL = STORE_FRONT_DOMAIN;

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const ProfilePage = () => {
    const [userData, setUserData] = useState({});
    const authToken = useSelector((state) => state.Login.token);
    const [isEditing, setIsEditing] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [showProfileEdit, setShowProfileEdit] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [profileHovered, setProfileHovered] = useState(false);


    const fetchUserData = async () => {
        try {
            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/detail/`;
            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            setUserData(response.data);
        } catch (error) {
            console.error("Error fetching user details:", error.message);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [authToken]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 2) {
                setActiveTab(tab);
            }
        }
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleMouseEntry = () => {
        setProfileHovered(true);
    };

    const handleMouseOut = () =>{
        setProfileHovered(false);
    }

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setShowProfileEdit(false);
    };



    const handleSaveEdit = async (updatedData) => {
        try {
            const formData = new FormData();
            formData.append("profile_image", updatedData.profile_image);
            formData.append("cover_image", updatedData.cover_image);

            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/image/update`;
            await axios.put(apiUrl, formData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            setIsEditing(false);
            setShowProfileEdit(false);
            setTimeout(() => {
                window.location.reload();
            }, 3000);

            fetchUserData();
        } catch (error) {
            console.error("Error updating profile:", error.message);
        }
    };

    // const handleSaveEdit = async (updatedData) => {
    //     try {
    //         const formData = new FormData();
    //         formData.append("profile_image", updatedData.profile_image);
    //         formData.append("cover_image", updatedData.cover_image);
    
    //         const apiUrl = `${BACKEND_DOMAIN}/business/merchant/image/update`;
    //         await axios.put(apiUrl, formData, {
    //             headers: {
    //                 Authorization: `Bearer ${authToken}`,
    //                 "Content-Type": "multipart/form-data",
    //             },
    //         });
    
    //         // Refresh the page after 3 seconds
    //         setTimeout(() => {
    //             window.location.reload();
    //         }, 3000);
    
    //         // Optionally, you can call fetchUserData() if you want to update the data without a full page refresh
    //         // fetchUserData();
    //     } catch (error) {
    //         console.error("Error updating profile:", error.message);
    //     }
    // };

    const handleProfileImageUpdate = () => {
        setShowProfileEdit(true);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="justify-content-center mb-5 mb-sm-0">
                        <Col md="12" className="h-100">
                            <Card>
                                <CardBody>
                                    <div className="d-flex flex-column flex-md-row">
                                        <div sm={12} md={3} className="text-center me-md-3 position-relative" 
                                                  onMouseEnter={handleMouseEnter}
                                                 onMouseLeave={handleMouseLeave}>
                                            <div className="mb-3 position-relative">
                                                <img
                                                    src={userData.cover_image}
                                                    alt="Cover Image"
                                                    style={{
                                                        objectFit: "cover",
                                                        borderRadius: 8,
                                                        height: "150px",
                                                        width: "100%",
                                                    }}
                                                />

<div
                                                    className="position-absolute bottom-0 end-0 me-1 mb-2"
                                                    style={{
                                                        cursor: "pointer",
                                                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                                                        borderRadius: "3%",
                                                        padding: "3px",
                                                        opacity: isHovered ? 1 : 0,
                                                         transition: "opacity 0.2s",
                                                        
                                                    }}
                                                    onClick={handleProfileImageUpdate}
                                                >
                                                <i className="mdi mdi-pencil font-size-18"></i>
                                                </div>
                                            </div>

                                            {/* Profile Image with Edit Icon */}
                                            <div className="position-relative" style={{ marginTop: "-50px",width:"130px" }}
                                                onMouseEnter={handleMouseEntry}
                                                onMouseLeave={handleMouseOut}
                                                >

                                                <img
                                                    src={userData.profile_image}
                                                    alt="Profile"
                                                    className="avatar-lg"
                                                    style={{
                                                        objectFit: "cover",
                                                        borderRadius: "50%",
                                                        border: "3px solid white",
                                                        height: "100px",
                                                        width: "100px",
                                                    }}
                                                />
                                                <div
                                                    className="position-absolute bottom-0 end-0 me-3 mb-2"
                                                    style={{
                                                        cursor: "pointer",
                                                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                                                        borderRadius: "5%",
                                                        padding: "3px",
                                                        opacity: profileHovered ? 1 : 0,
                                                        transition: "opacity 0.2s",
                                                    }}
                                                    onClick={handleProfileImageUpdate}
                                                >
                                                <i className="mdi mdi-pencil font-size-18"></i>
                                                </div>
                                            </div>

                                            <h5 className="mt-3">
                                                <b>{userData.name && capitalizeFirstLetter(userData.name)}</b>
                                            </h5>

                                            <CopyLink url={WEB_APP_URL} slugName={userData?.slug} />

                                            {isEditing && <ProfileEdit userData={userData} />}
                                        </div>
                                        <div
                                            sm={12}
                                            md={9}
                                            className="mt-xs-3 d-flex flex-column"
                                            style={{ width: "100%" }}
                                        >
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 1 })}
                                                        onClick={() => {
                                                            toggleTab(1);
                                                        }}
                                                    >
                                                        Business Info
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 2 })}
                                                        onClick={() => {
                                                            toggleTab(2);
                                                        }}
                                                    >
                                                        Logistics
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId={1}>
                                                    <Row>
                                                        <Col
                                                            sm="12"
                                                            className="d-flex flex-column align-items-start p-4"
                                                        >
                                                            <h6 style={{ fontSize: "13px" }}>Business Type</h6>
                                                            <p>
                                                                <b>{userData.type}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Email</h6>
                                                            <p>
                                                                <b>{userData.email}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>KRA PIN</h6>
                                                            <p>
                                                                <b>{userData.kra_pin}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>
                                                                WhatsApp Business Phone No
                                                            </h6>
                                                            <p>
                                                                <b>{userData.phone_number}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>
                                                                Contact Person Phone No
                                                            </h6>
                                                            <p>
                                                                <b>{userData.alt_phone_number}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Contact Person</h6>
                                                            <p>
                                                                <b>{userData.contact_person}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Location</h6>
                                                            <p>
                                                                <b>{userData.location}</b>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <Row>
                                                        <Col sm="12" className="p-4">
                                                            <h6 style={{ fontSize: "13px" }}>VAT:</h6>
                                                            <p>
                                                                <b>16%</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>
                                                                Delivery Fees Within Nairobi:
                                                            </h6>
                                                            <p>
                                                                <b>KES 700</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Opening Hours:</h6>
                                                            <p>
                                                                <b>Monday - Friday: 08:00AM</b>
                                                            </p>
                                                            <p>
                                                                <b>Saturday & Sunday: 08:00AM</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Closing Hours:</h6>
                                                            <p>
                                                                <b>Monday - Friday: 9:00PM</b>
                                                            </p>
                                                            <p>
                                                                <b>Saturday & Sunday: 8:00PM</b>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                            <div xs={12} lg={12} className="d-flex justify-content-end">
                                                <Button
                                                    style={{
                                                        backgroundColor: "#5664d2",
                                                        alignSelf: "end",
                                                    }}
                                                    onClick={handleEditClick}
                                                >
                                                    Update Profile Details
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {/* Render the EditProfileForm modal if showProfileEdit is true */}
                {showProfileEdit && (
                    <EditProfileForm
                        onCancel={handleCancelEdit}
                        onSave={handleSaveEdit}
                        userData={userData}
                    />
                )}
            </div>
        </React.Fragment>
    );
};

export default ProfilePage;
