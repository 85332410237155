import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Col, Row, Card, CardBody, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button,Alert,  Dropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BACKEND_DOMAIN from "../../../service";
import dateFormatter from "../utilities/dateFormatter";
import { ORDER_TYPE_COLORS } from "../utilities/orderTypes";
import { fetchOrders, fetchWaiterCards } from './utilities/fetchOrder';

const AllPendingOrders = () => {
    const token = useSelector((state) => state.Login.token);

    const [orders, setOrders] = useState([]);
    const [menuOpen, setMenuOpen] = useState({});
    const [modal, setModal] = useState(false);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
    const [orderListIsEmpty, setOrderListIsEmpty] = useState(false);
    const [ordeSuccessmessage, setOrderSuccessMessage] = useState('');
    const [waiterCard, setwaiterCard]=useState([]);

    // Order Badge color for the modal:
    const [orderBadgeColor, setOrderBadgeColor] = useState("success");

    const toggleModal = () => {
        setModal(!modal);
    };
    const openModal = (orderId) => {
        const selectedOrder = orders.find((order) => order.id === orderId);

        setSelectedOrderDetails(selectedOrder);
        setOrderBadgeColor(ORDER_TYPE_COLORS[selectedOrder.order_type]);
        toggleModal();
    };

    // fetch card  data

    const fetchCardData = async () => {
        try {
            const data = await fetchWaiterCards();
            if (data) {
                setwaiterCard(data);
            }
        } catch (error) {
            console.error("Error setting card data:", error);
        }
    };


  useEffect(() => {
    fetchCardData();
  }, []);


    console.log(' waiters pending orders ');


    // const handleAcceptReject = async (orderId, newStatus) => {
    //     console.log('order ',orderId,' status ',newStatus);
    //     try {
    //         const apiUrl = `${BACKEND_DOMAIN}/business/order/update-status/${orderId}/`;
    //         const response = await fetch(apiUrl, {
    //             method: "PATCH",
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //                 status: newStatus,
    //             }),
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }
    //      console.log('order updated');
    //         // Assuming your API response includes the updated order details, update the state accordingly
    //         const updatedOrder = await response.json();
    //         setOrders((prevOrders) =>
    //             prevOrders.map((order) =>
    //                 order.id === updatedOrder.id ? { ...order, status: updatedOrder.status } : order
    //             )
    //         );

    //         setMenuOpen((prevMenuOpen) => ({
    //             ...prevMenuOpen,
    //             [orderId]: false,
    //         }));

    //         // Fetch orders again to update the orders list
    //         fetchOrders();

    //         setOrderSuccessMessage("Waiter removed successfully!");
    //         setTimeout(() => {
    //             setOrderSuccessMessage("");
    //         }, 3000);

    //     } catch (error) {
    //         console.error("Error updating order status:", error);
    //     } finally {
    //         if (modal) {
    //             setModal(false);
    //         }
    //     }
    // };



    const handleAcceptReject = async (orderId, newStatus) => {
        try {
            const apiUrl = `${BACKEND_DOMAIN}/business/order/update-status/${orderId}/`;
            const response = await fetch(apiUrl, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    status: newStatus,
                }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const updatedOrder = await response.json();
            setOrders((prevOrders) =>
                prevOrders.map((order) =>
                    order.id === updatedOrder.id ? { ...order, status: updatedOrder.status } : order
                )
            );
    
            // Trigger re-fetch of orders to reflect latest data
            fetchOrders().then((normalizedData) => {
                const filteredOrders = normalizedData.filter(order => order.status === "pending");
                if (filteredOrders.length === 0) {
                    setOrderListIsEmpty(true);
                }
                setOrders(filteredOrders.reverse());
            }).catch(error => {
                console.error("Error fetching orders:", error);
            });
            fetchCardData();
    
            setOrderSuccessMessage("Order updated successfully!");
            setTimeout(() => {
                setOrderSuccessMessage("");
            }, 3000);
    
        } catch (error) {
            console.error("Error updating order status:", error);
        } finally {
            if (modal) {
                setModal(false);
            }
        }
    };
    
    
  
    useEffect(() => {
        fetchOrders().then(normalizedData => {
            const filteredOrders = normalizedData.filter(order => order.status === "pending");
            if (filteredOrders.length === 0) {
                setOrderListIsEmpty(true);
            }
            setOrders(filteredOrders.reverse());
          ///  console.log('the waiters orders ',filteredOrders);
        }).catch(error => {
            console.error("Error fetching orders:", error);
        });
    }, []);

    
    const authData = JSON.parse(localStorage.getItem('authUser'));
    const role = authData?.role || '';

    const columns = useMemo(
        () => {
            const baseColumns = [
                {
                    Header: "OrderID",
                    accessor: "order_number",
                    disableFilters: true,
                    filterable: false,
                },
                {
                    Header: "Customer",
                    accessor: "customer_name",
                    disableFilters: true,
                    filterable: false,
                },
                {
                    Header: "Amount",
                    accessor: "total_order_amount",
                    disableFilters: true,
                    filterable: false,
                },
                {
                    Header: "Order Type",
                    accessor: "order_type",
                    Cell: ({ cell: { value, row } }) => {
                        let typeIcon;
                        let tooltipContent;

                        if (value === "DELIVERY" || "Delivery") {
                            typeIcon = <i className="mdi mdi-moped"></i>;
                            tooltipContent = row.original.order_type;
                        } else if (value === "RESERVE" || "Book Table") {
                            typeIcon = <i className="mdi mdi-calendar-clock-outline"></i>;
                            tooltipContent = row.original.order_type;
                        } else if (value === "PICK-UP" || "pickup") {
                            typeIcon = <i className="mdi mdi-car-back"></i>;
                            tooltipContent = row.original.order_type;
                        } else if (value === "IN-STORE" || "Dine In") {
                            typeIcon = <i className="mdi mdi-silverware-fork-knife"></i>;
                            tooltipContent = row.original.order_type;
                        }

                        return (
                            <React.Fragment>
                                <div>{tooltipContent}</div>
                            </React.Fragment>
                        );
                    },
                    disableFilters: true,
                    filterable: false,
                },
                {
                    Header: "Date",
                    accessor: "created_at",
                    Cell: ({ cell: { value } }) => {
                        const datePart = dateFormatter(value);
                        return datePart;
                    },
                    disableFilters: true,
                    filterable: false,
                },
                {
                    Header: "Status",
                    accessor: "payment_status",
                    disableFilters: true,
                    filterable: false,
                    Cell: ({ cell: { value } }) => {
                        let statusBadge;
                        if (value === "PAID") {
                            statusBadge = <span className="badge bg-success-subtle text-success text-uppercase">PAID</span>;
                        } else if (value === "UNPAID") {
                            statusBadge = <span className="badge bg-danger-subtle text-danger text-uppercase">UNPAID</span>;
                        }
                        return <React.Fragment>{statusBadge}</React.Fragment>;
                    },
                },
                {
                    Header: "Stage",
                    accessor: "status",
                    Cell: ({ cell: { value } }) => {
                        let statusBadge;
                        statusBadge = <span className="badge bg-warning-subtle text-warning text-uppercase">Pending</span>;

                        return <React.Fragment>{statusBadge}</React.Fragment>;
                    },
                    disableFilters: true,
                    filterable: false,
                },
                {
                    Header: "Action",
                    accessor: (cellProps) => {
                        const orderId = cellProps.id ? cellProps.id : null;
    
                        return (
                            <React.Fragment>
                                <div className="d-flex align-items-center">
                                    {/* <Link to="#" className="me-3 text-primary" onClick={() => openModal(orderId)}>
                                        <i className="mdi mdi-eye font-size-18"></i>
                                    </Link> */}
                                    <Dropdown isOpen={menuOpen[orderId]} toggle={() => handleToggleDropdown(orderId)}>
                                        <DropdownToggle tag="i" className="arrow-none card-drop">
                                            <i className="mdi mdi-dots-vertical"></i>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ zIndex: 1000 }} className="dropdown-menu-end">
                                            <DropdownItem
                                                onClick={() => handleAcceptReject(orderId, "complete")}
                                                disabled={
                                                    cellProps.status === "complete" || cellProps.status === "rejected"
                                                }
                                            >
                                                Accept
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => handleAcceptReject(orderId, "rejected")}
                                                disabled={
                                                    cellProps.status === "complete" || cellProps.status === "rejected"
                                                }
                                            >
                                                Reject
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </React.Fragment>
                        );
                    },
                    disableFilters: true,
                    filterable: false,
                },
                // {
                //     Header: "Action",
                //     accessor: (cellProps) => {
                //         const orderId = cellProps.id ? cellProps.id : null;
                //         return (
                //             <React.Fragment>
                //                 <div className="d-flex align-items-center justify-content-center">
                //                     <Link to="#" className="me-3 text-primary" onClick={() => openModal(orderId)}>
                //                         <i className="mdi mdi-eye font-size-18"></i>
                //                     </Link>
                //                 </div>
                //             </React.Fragment>
                //         );
                //     },
                //     disableFilters: true,
                //     filterable: false,
                // },
            ];

            if (role === 'waiter') {
                baseColumns.splice(1, 0, {
                    Header: "Merchant",
                    accessor: "merchant",
                    disableFilters: true,
                    filterable: false,
                });
            }

            return baseColumns;
        },
        [menuOpen, orders, role]
    );

    const handleToggleDropdown = (orderId) => {
        setMenuOpen((prevMenuOpen) => {
            return { ...prevMenuOpen, [orderId]: !prevMenuOpen[orderId] };
        });
    };

    const breadcrumbItems = [
        { title: "Incomplete Orders", link: "#" },
        { title: "Pending Orders", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Breadcrumbs title="Pending Orders" breadcrumbItems={breadcrumbItems} />
                    {/* the cards */}
                    <Row>
                        <Col md={3}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">Total Orders</p>
                                            <h4 className="mb-0">{waiterCard.total_orders}</h4>
                                        </div>
                                        <div className="text-primary">
                                            <i className="mdi mdi-order-bool-descending font-size-24"></i>
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge bg-success-subtle text-success font-size-11 me-1"></span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">Pending Orders</p>
                                            <h4 className="mb-0">{waiterCard.pending_orders}</h4>
                                        </div>
                                        <div className="text-warning">
                                            <i className="mdi mdi-receipt font-size-24"></i>
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge bg-success-subtle text-success font-size-11 me-1"></span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">Rejected Orders</p>
                                            <h4 className="mb-0">{waiterCard.rejected_orders}</h4>
                                        </div>
                                        <div className="text-danger">
                                            <i className="mdi mdi-cancel font-size-24"></i>
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge bg-success-subtle text-success font-size-11 me-1"></span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">Approved</p>
                                            <h4 className="mb-0">{waiterCard.approved_orders}</h4>
                                        </div>
                                        <div className="text-success">
                                            <i className="mdi mdi-check-circle font-size-24"></i>
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge bg-success-subtle text-success font-size-11 me-1"></span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* the cards */}
                  

                    <Card>
                        <CardBody>
                        {ordeSuccessmessage && <Alert color="success">{ordeSuccessmessage}</Alert>}
                            <TableContainer
                                columns={columns || []}
                                data={orders || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={orderListIsEmpty}
                                isEmptyContent="There are currently no pending orders on the platform."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal
                isOpen={modal}
                role="document"
                autoFocus={true}
                centered={true}
                size="lg"
                className="composemodal"
                tabIndex="-1"
                toggle={toggleModal}
            >
                <div className="modal-content">
                    <ModalHeader className="position-relative">
                        <span className="">ORDER SUMMARY</span>
                        <span
                            style={{ position: "absolute", right: 0 }}
                            className={`badge bg-${orderBadgeColor}-subtle text-${orderBadgeColor} text-uppercase p-2 me-3`}
                        >
                            {selectedOrderDetails?.order_type ? selectedOrderDetails.order_type : "None"}
                        </span>
                    </ModalHeader>
                    <ModalBody style={{ maxHeight: "800px", overflowY: "auto" }}>
                        {selectedOrderDetails && (
                            <div>
                                <div className="d-flex flex-column flex-sm-row justify-content-between">
                                    <div>
                                        <h6 className="me-2 mb-3" style={{ fontSize: "14px" }}>
                                            CUSTOMER DETAILS
                                        </h6>

                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Name :</h6>
                                            <p className="ms-2 mt-2">{selectedOrderDetails.customer_name}</p>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Email :</h6>
                                            <p className="ms-2 mt-2">{selectedOrderDetails.customer_email}</p>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Phone NO :</h6>
                                            <p className="ms-2 mt-2">{selectedOrderDetails.phone_number}</p>
                                        </div>

                                        {selectedOrderDetails.order_type === "Delivery" && (
                                            <div>
                                                <h6 style={{ fontSize: "12px" }}>Address :</h6>
                                                <p className="ms-2 mt-2">{selectedOrderDetails.delivery_address}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex-shrink-0">
                                        <h6 className="me-2 mb-3" style={{ fontSize: "14px" }}>
                                            ORDER DETAILS
                                        </h6>
                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Order Number :</h6>
                                            <p className="ms-2 mt-2">{selectedOrderDetails.order_number}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Order Type :</h6>
                                            <p className="ms-2 mt-2">{selectedOrderDetails.order_type}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Status :</h6>
                                            <p className="ms-2 mt-2">{selectedOrderDetails.status}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Order Date :</h6>
                                            <p className="ms-2 mt-2">{dateFormatter(selectedOrderDetails.created_at)}</p>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h6 className="me-2 mb-3" style={{ fontSize: "14px" }}>
                                        ORDER ITEMS
                                    </h6>
                                    {selectedOrderDetails.items.map((item, index) => (
                                        <div key={index} className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <p style={{ fontSize: "12px" }}>{item.name}</p>
                                                <p className="ms-2 mt-2">{item.quantity}</p>
                                            </div>
                                            <p style={{ fontSize: "12px" }} className="me-2">{item.price}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </ModalFooter>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default AllPendingOrders;
