import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../../components/Common/TableContainer";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Alert
} from "reactstrap";
import { useSelector } from "react-redux";
import { fetchTable } from "./utilities/tabledata";
import { Link } from "react-router-dom";

const Transactions = () => {
  const [allTableList, setAllTableList] = useState([]);
  const [menuOpen, setMenuOpen] = useState({});
  const token = useSelector((state) => state.Login.token);
  const [modal, setModal] = useState(false);
  const [selectedTableDetails, setSelectedTableDetails] = useState(null);
  const [waiterIdToDelete, setWaiterIdToDelete] = useState(null);
  const [tableIdToDelete, setTableIdToDelete] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmClearModal, setConfirmClearModal] = useState(false);
  const [addwaiterModal, setAddWaiterModal]=useState(false);
  const [deletionSuccessMessage, setDeletionSuccessMessage] = useState("");
  const [selectedWaiterIds, setSelectedWaiterIds] = useState([]);
  const [waitertable, setTableWaiter]=useState(null)
  const [allwaiters, setAllwaiters]=useState([]);
  const [filteredWaiters, setFilteredWaiters] = useState([]);
 const [successmessage, setSuccessMessage] = useState('');
 const [errorMessage, setErrorMessage]=useState('');


  const tableapi = `https://backoffice.route.money/api/v1/business/merchant/tables`;
  const WaitersApi = `https://backoffice.route.money/api/v1/admin/users/list`;

  const toggleModal = () => {
    setModal(!modal);
};

// handle waiter change
const handleWaiterSelection = (event, waiterId) => {
  if (event.target.checked) {
      setSelectedWaiterIds(prevIds => [...prevIds, waiterId]);
  } else {
      setSelectedWaiterIds(prevIds => prevIds.filter(id => id !== waiterId));
  }
};




// fetch merchant users
useEffect(() => {
  const fetchWaiters = async () => {
      try {
          const response = await fetch(`${WaitersApi}`, {
              method: "GET",
              headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
              },
          });

          // Parse the response as JSON
          const data = await response.json();

          //console.log("waiters", data);

          // Check if data exists
          if (data) {
             setAllwaiters(data);
                          // Check if data is an array
          
          } else {
              throw new Error("Unexpected response format");
          }
      } catch (error) {
          console.error("Error fetching users:", error);
      }
  };

  fetchWaiters(); // Call the fetchWaiters function
}, [WaitersApi, token]); // Include dependencies like `WaitersApi` and `token`


  const fetchAllTables = async () => {
    const authData = JSON.parse(localStorage.getItem("authUser"));

    if (!authData || !authData.token) {
    //  console.error("No auth data found in local storage.");
      return;
    } else {
  
    }

    try {
      const results = await fetchTable(authData.token, tableapi);
      if (results.length === 0) {
        setAllTableList([]);
      } else {
        setAllTableList(results);
        console.log('table fetched ',results)
      }
    } catch (error) {
      console.error("Error fetching tables: ", error);
    }
  };

  useEffect(() => {
    fetchAllTables();
  }, []);



  const handleStatusChange = async (orderId, newStatus) => {
    try {
      const apiUrl = `https://backoffice.route.money/api/v1/business/merchant/table/update/status/${orderId}`;
      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: newStatus,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      await fetchAllTables();
    } catch (error) {
      console.error("Error updating order status:", error);
    } finally {
      setMenuOpen((prevMenuOpen) => ({
        ...prevMenuOpen,
        [orderId]: false,
      }));
    }
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);
  const toggleClearConfirmModal = () => setConfirmClearModal(!confirmClearModal)
  const toggleWaiterAddModal = () => setAddWaiterModal(!addwaiterModal)
  //remove waiter from table
  const openConfirmModal = (waiterId, tableId) => {
    setWaiterIdToDelete(waiterId);
    setTableIdToDelete(tableId);

    toggleConfirmModal();
    };

    // confirm remove all waiters from table
    const openClearConfirmModal = (tableId) => {
      setTableIdToDelete(tableId);
      toggleClearConfirmModal();
      };


      const addWaiter = (tableId) => {
        // Set the table ID to the state
        setTableWaiter(tableId);

        const filteredWaiters = allwaiters.data.filter(waiter => {
        
          return waiter.role === 'Waiter' && 
                 !selectedTableDetails.waiters.some(tableWaiter => tableWaiter.id === waiter.id);
      });
            // Set the filtered waiters to the state (or handle them as needed)
            setFilteredWaiters(filteredWaiters);
              // Open the modal
            toggleWaiterAddModal();
    
    };
    

    const confirmDelete = () => {
      if (waiterIdToDelete && tableIdToDelete) {
        deleteWaiters(waiterIdToDelete, tableIdToDelete);
          setWaiterIdToDelete(null);
          setTableIdToDelete(null);
          toggleConfirmModal();
      }
  };

  const confirmWaitersDelete = () =>{
    if (tableIdToDelete) {
       clearAllWaiters(tableIdToDelete);
       setTableIdToDelete(null);
       toggleClearConfirmModal();
      }
  }

  // add waiters to table
  const submitWaiterSelection = async () => {
  
    const apiAssignWaiters = `https://backoffice.route.money/api/v1/business/merchant/tables/${waitertable}/assign-users/`;
    
    try {
        const response = await fetch(apiAssignWaiters, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                user_ids: selectedWaiterIds,
            }),
        });

        const data = await response.json();

        if (!response.ok) {
            setErrorMessage('Assignment failed: ', data.errors);
            throw new Error(data.message || "Reassignment unsuccessful, please try again");
        } else {
            setSuccessMessage(`Waiters attached successfully!`);
         

            // Update the allTableList with the newly assigned waiters
            const updatedTables = allTableList.map((table) => {
                if (table.id === waitertable) {
                    return {
                        ...table,
                        waiters: [...table.waiters, ...filteredWaiters.filter(waiter => selectedWaiterIds.includes(waiter.id))]
                    };
                }
                return table;
            });
            setAllTableList(updatedTables);

            // Update the selectedTableDetails if it matches the waitertable
            if (selectedTableDetails && selectedTableDetails.id === waitertable) {
                setSelectedTableDetails((prevDetails) => ({
                    ...prevDetails,
                    waiters: [...prevDetails.waiters, ...filteredWaiters.filter(waiter => selectedWaiterIds.includes(waiter.id))]
                }));
            }

            setTimeout(() => {
                setSuccessMessage("");
            }, 2000);
        }
    } catch (error) {
        setErrorMessage("Error occurred, try again!");
        console.log('Data insertion failed:', error);
        setTimeout(() => {
            setErrorMessage("");
        }, 2000);
    }

    // Close the modal after the operation
    toggleWaiterAddModal();
};

  const clearAllWaiters = (tableId) => {
  
    const apiUrlDelete = `https://backoffice.route.money/api/v1/business/merchant/table/clear/users/${tableId}/`;

    fetch(apiUrlDelete, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
        if (response.ok) {
            // Update the allTableList to reflect that the waiters have been cleared
            const updatedTables = allTableList.map((table) => {
                if (table.id === tableId) {
                    return {
                        ...table,
                        waiters: []  // Clear all waiters for this table
                    };
                }
                return table;
            });

            setAllTableList(updatedTables);

            // Update the waiters list in the selectedTableDetails if it's the same table
            if (selectedTableDetails && selectedTableDetails.id === tableId) {
                setSelectedTableDetails((prevDetails) => ({
                    ...prevDetails,
                    waiters: []  // Clear all waiters in the selected table details
                }));
            }

            // Set a success message and clear it after a timeout
            setDeletionSuccessMessage("All waiters cleared from the table!");
            setTimeout(() => {
                setDeletionSuccessMessage("");
            }, 3000);
        } else {
            console.error("Error clearing waiters:", response.status);
        }
    })
    .catch((error) => console.error("Error clearing waiters:", error));
};


   const deleteWaiters = (waiterId, tableId) => {
    const apiUrlDelete = `https://backoffice.route.money/api/v1/business/merchant/table/${tableId}/remove-user/${waiterId}/`;

    fetch(apiUrlDelete, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            if (response.ok) {

                  // Update the allTableList and selectedTableDetails after successful deletion
                  const updatedTables = allTableList.map((table) => {
                    if (table.id === tableId) {
                        return {
                            ...table,
                            waiters: table.waiters.filter((waiter) => waiter.id !== waiterId)
                        };
                    }
                    return table;
                });

                setAllTableList(updatedTables);

                // Update the waiters list in the selectedTableDetails
                if (selectedTableDetails && selectedTableDetails.id === tableId) {
                  setSelectedTableDetails((prevDetails) => ({
                      ...prevDetails,
                      waiters: prevDetails.waiters.filter((waiter) => waiter.id !== waiterId)
                  }));
              }
                setDeletionSuccessMessage("Waiter removed successfully!");
                setTimeout(() => {
                    setDeletionSuccessMessage("");
                }, 3000);
            } else {
                console.error("Error removing waiter:", response.status);
            }
        })
        .catch((error) => console.error("Error dremoving waiter:", error));
 };


  const columns = useMemo(
    () => [
      // {
      //   Header: "TableID",
      //   accessor: "id",
      //   disableFilters: true,
      //   filterable: false,
      // },
      {
        Header: "Table Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "description",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Capacity",
        accessor: "capacity",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => {
            let statusBadge;
            switch (value) {
                case "available":
                    statusBadge = <span className="badge bg-success-subtle text-success text-uppercase">Available</span>;
                    break;
                case "reserved":
                    statusBadge = <span className="badge bg-warning-subtle text-warning text-uppercase">Reserved</span>;
                    break;
                case "occupied":
                    statusBadge = <span className="badge bg-danger-subtle text-danger text-uppercase">Occupied</span>;
                    break;
                default:
                    statusBadge = <span className="badge bg-secondary-subtle text-secondary text-uppercase">Unknown</span>;
                    break;
            }
            return <React.Fragment>{statusBadge}</React.Fragment>;
        },
    },
    
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   disableFilters: true,
      //   filterable: false,
      // },
      {
        Header: "Action",
        accessor: (cellProps) => {
          const orderId = cellProps.id ? cellProps.id : null;
          return (
            <React.Fragment>
            <div className="d-flex align-items-center">
              <Link to="#" className="me-3 text-primary" onClick={() => openModal(orderId)}>
                  <i className="mdi mdi-eye font-size-18"></i>
              </Link>
              <Dropdown isOpen={menuOpen[orderId]} toggle={() => handleToggleDropdown(orderId)}>
                <DropdownToggle tag="i" className="arrow-none card-drop">
                  <i className="mdi mdi-dots-vertical"></i>
                </DropdownToggle>
                <DropdownMenu style={{ zIndex: 1000 }} className="dropdown-menu-end">
                  <DropdownItem
                    onClick={() => handleStatusChange(orderId, "available")}
                  >
                    Available
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleStatusChange(orderId, "reserved")}
                  >
                    Reserved
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleStatusChange(orderId, "occupied")}
                  >
                    Occupied
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              </div>
            </React.Fragment>
          );
        },
        disableFilters: true,
        filterable: false,
      },
    ],
    [menuOpen,allTableList]
  );

  const handleToggleDropdown = (orderId) => {
    setMenuOpen((prevMenuOpen) => ({
      ...prevMenuOpen,
      [orderId]: !prevMenuOpen[orderId],
    }));
  };

  const openModal = (orderId) => {
   // console.log('All table ',allTableList);
      const selectedTable = allTableList.find((table) => table.id === orderId);
     // console.log('selected table ',selectedTable);
      setSelectedTableDetails(selectedTable);
     // setOrderBadgeColor(ORDER_TYPE_COLORS[selectedOrder.order_type]);
      toggleModal();
  };


  const breadcrumbItems = [
    { title: "Tables", link: "#" },
    { title: "All Tables", link: "#" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

        <Breadcrumbs title="Add A Table" breadcrumbItems={breadcrumbItems} />
                    <Link to="/route-money/merchant/table/add" className="btn btn-primary mb-3">
                        Add Table
                    </Link>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns}
                data={allTableList}
                isPagination={true}
                isGlobalFilter={true}
                iscustomPageSize={true}
                isBordered={false}
                customPageSize={10}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal
                isOpen={modal}
                role="document"
                autoFocus={true}
                centered={true}
                size="lg"
                className="composemodal"
                tabIndex="-1"
                toggle={toggleModal}
            >
                <div className="modal-content">
                    <ModalHeader className="position-relative">
                        <span className="">TABLE DETAILS</span>
                        {deletionSuccessMessage && <Alert color="success">{deletionSuccessMessage}</Alert>}
                        {successmessage && <Alert color="success">{successmessage}</Alert>}
                        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

                        <span
                            style={{ position: "absolute", right: 0 }}

                        >
                          
                        </span>
                    </ModalHeader>

                    <ModalBody style={{ maxHeight: "700px", overflowY: "auto" }}>
                        {selectedTableDetails && (
                            <div>
                                <div className="d-flex flex-column flex-sm-row justify-content-between">
                                    <div>


                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Number / Name :</h6>
                                            <p className="ms-2 mt-2">{selectedTableDetails.name}</p>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Description :</h6>
                                            <p className="ms-2 mt-2">{selectedTableDetails.description}</p>
                                        </div>




                                    </div>

                                    <div className="mt-3 mt-sm-0">

                                    <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Capacity :</h6>
                                            <p className="ms-2 mt-2">{selectedTableDetails.capacity}</p>
                                        </div>

                                    <div className="d-flex align-items-center">
                                            <h6 style={{ fontSize: "12px" }}>Status :</h6>
                                            <p className="ms-2 mt-2">{selectedTableDetails.status}</p>
                                        </div>

                                    </div>
                                </div>

                                {/* Separation Line */}
                                <hr style={{ border: "1px solid #ddd", margin: "10px 0" }} />

                                {/* Order Details Section */}
                                <div>
                                    <h6 className="me-2 mb-3" style={{ fontSize: "14px" }}>
                                        WAITERS ATTACHED
                                    </h6>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                           
                                                <th>Waiter Name</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {selectedTableDetails && selectedTableDetails.waiters && selectedTableDetails.waiters.length > 0 ? (
                                              selectedTableDetails.waiters.map((item, index) => (
                                                  <tr key={index}>
                                                      <td>{item.first_name}</td>
                                                      <td>{item.email}</td>
                                                      <td>{item.phone_number}</td>
                                                      <td>

                                                  <Link to="#" className="me-3 text-danger" onClick={() => openConfirmModal(item.id,selectedTableDetails.id)}>
                                                      <i className="mdi mdi-delete font-size-18"></i>
                                                  </Link>
                                                      </td>
                                                  </tr>
                                              ))
                                          ) : (
                                              <tr>
                                                  <td colSpan="4">No waiters attached</td>
                                              </tr>
                                          )}
                                      </tbody>
                                        <tfoot>
                                         
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                            cancel
                        </Button>{" "}
                        <Button color="success" onClick={() => addWaiter(selectedTableDetails.id)}>
                        Add a Waiter
                        </Button>
                        <Button color="danger" onClick={() => openClearConfirmModal(selectedTableDetails.id)}>
                        Clear Waiters
                        </Button>
                    </ModalFooter>
                </div>
            </Modal> 
            <Modal isOpen={confirmModal} toggle={toggleConfirmModal}>
                <ModalHeader toggle={toggleConfirmModal}>Confirm Remove</ModalHeader>
                <ModalBody>Are you sure you want toremove this waiter?</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleConfirmModal}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={confirmDelete}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={confirmClearModal} toggle={toggleClearConfirmModal}>
                <ModalHeader toggle={toggleConfirmModal}>Confirm Remove</ModalHeader>
                <ModalBody>Are you sure you want to remove all waiters?</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleClearConfirmModal}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={confirmWaitersDelete}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={addwaiterModal} toggle={toggleWaiterAddModal}>
    <ModalHeader toggle={toggleWaiterAddModal}>Add Waiters to Table</ModalHeader>
    <ModalBody>
        <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
            {filteredWaiters.map(waiter => (
                <div key={waiter.id}>
                    <input 
                        type="checkbox" 
                        value={waiter.id} 
                        onChange={(e) => handleWaiterSelection(e, waiter.id)}
                    />
                    {waiter.first_name} {waiter.last_name}
                </div>
            ))}
        </div>
    </ModalBody>
    <ModalFooter>
        <Button color="secondary" onClick={toggleWaiterAddModal}>
            Cancel
        </Button>
        <Button color="primary" onClick={submitWaiterSelection}>
            Add Waiter(s)
        </Button>
    </ModalFooter>
</Modal>



    </React.Fragment>
  );
};

export default Transactions;
