import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import { Link } from "react-router-dom";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Container,  Dropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem } from "reactstrap";
import { useSelector } from "react-redux";
import { fetchUsers } from "../utilities/MerchantStaffs";
import dateFormatter from "../../Orders/utilities/dateFormatter";

const User = () => {
    const [allUsersList, setAllUsersList] = useState([]);
    const [menuOpen, setMenuOpen] = useState({});
    const [usersListEmpty, setUsersListEmpty] = useState(false);
    const token = useSelector((state) => state.Login.token);

    const USERS_LIST_URL = `https://backoffice.route.money/api/v1/admin/users/`;
    const BUSINESS_PARTNER_USERS_LIST_URL = `https://backoffice.route.money/api/v1/admin/users/list`;

    const fetchAllUsers = async () => {
        // Retrieve the token and business partner status from local storage
        const authData = JSON.parse(localStorage.getItem("authUser"));

        if (!authData || !authData.token) {
           // console.error("No auth data found in local storage.");
            return;
        } else {
        
        }

        // Determine the URL based on is_business_partner status
        const apiUrl = authData.is_business_partner ? BUSINESS_PARTNER_USERS_LIST_URL : USERS_LIST_URL;

        try {
            const results = await fetchUsers(authData.token, apiUrl);

            if (results.length === 0) {
                setUsersListEmpty(true);
            } else {
                let processedUsers = [];
                if (!authData.is_business_partner) {
                    // Process the users based on their roles
                    processedUsers = results
                    .filter(user => !user.is_superuser && !user.is_business_partner)
                        .map(user => {
                            if (user.is_superuser && !user.is_business_partner) {
                                return { ...user, role: "Route Admin" };
                            } else if (!user.is_superuser && user.is_business_partner) {
                                return { ...user, role: "Merchant" };
                            } else {
                                return user;
                            }
                        });
                } else {
                    processedUsers = results;
                }
                setAllUsersList(processedUsers);
            }
        } catch (error) {
            console.error("Error fetching users: ", error);
        }
    };

    useEffect(() => {
        fetchAllUsers();
    }, []);

    // activate deactivate user
    const handleStatusChange = async (orderId, newStatus) => {
        try {
          const apiUrl = `https://backoffice.route.money/api/v1/admin/user/update/status/${orderId}`;
          const response = await fetch(apiUrl, {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                is_active: newStatus,
            }),
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
    
          await fetchAllUsers();
        } catch (error) {
          console.error("Error updating order status:", error);
        } finally {
          setMenuOpen((prevMenuOpen) => ({
            ...prevMenuOpen,
            [orderId]: false,
          }));
        }
      };
    

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "first_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Role",
                accessor: "role",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Email",
                accessor: "email",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Phone Number",
                accessor: "phone_number",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date Created",
                accessor: "created_at",
                Cell: ({ cell: { value } }) => {
                    const finalDate = dateFormatter(value);
                    return finalDate;
                },
                disableFilters: true,
                filterable: false,
            },
            {
              Header: "Status",
              accessor: "is_active",
              disableFilters: true,
              filterable: false,
              Cell: ({ cell: { value } }) => {
                  let statusBadge;
                  if (value) {
                      statusBadge = <span className="badge bg-success-subtle text-success text-uppercase">Active</span>;
                  } else {
                      statusBadge = <span className="badge bg-danger-subtle text-danger text-uppercase">Inactive</span>;
                  }
                  return <React.Fragment>{statusBadge}</React.Fragment>;
              },
          },
          
            // {
            //     Header: "Status",
            //     accessor: "is_active",
            //     Cell: ({ cell: { value } }) => {
            //         return value ? "Active" : "Inactive";
            //     },
            //     disableFilters: true,
            //     filterable: false,
            // },
            {
                Header: "Action",
                accessor: (cellProps) => {
                  const orderId = cellProps.id ? cellProps.id : null;
                  return (
                    <React.Fragment>
                      <Dropdown isOpen={menuOpen[orderId]} toggle={() => handleToggleDropdown(orderId)}>
                        <DropdownToggle tag="i" className="arrow-none card-drop">
                          <i className="mdi mdi-dots-vertical"></i>
                        </DropdownToggle>
                        <DropdownMenu style={{ zIndex: 1000 }} className="dropdown-menu-end">
                          <DropdownItem
                            onClick={() => handleStatusChange(orderId, "true")}
                          >
                            Active
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleStatusChange(orderId, "false")}
                          >
                            Inactive
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </React.Fragment>
                  );
                },
                disableFilters: true,
                filterable: false,
              },
        ],
        [menuOpen]
    );

    const handleToggleDropdown = (orderId) => {
        console.log("tapped id", orderId);
        setMenuOpen((prevMenuOpen) => ({
          ...prevMenuOpen,
          [orderId]: !prevMenuOpen[orderId],
        }));
      };

    const breadcrumbItems = [
        { title: "User Management", link: "/" },
        { title: "All Users", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Breadcrumbs title="Create New User" breadcrumbItems={breadcrumbItems} />
                    <Link to="/route-money/create-user" className="btn btn-primary mb-3">
                        Create user
                    </Link>

                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={allUsersList || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={usersListEmpty}
                                isEmptyContent="There are no users currently."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default User;
