import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import TableContainer from "../../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Card, CardBody, Container } from "reactstrap";
import BACKEND_DOMAIN from "../../../../service/index";
import axios from "axios";

const MerchantTransactions = () => {
    const authToken = useSelector((state) => state.Login.token);

    const [transactions, setTransactions] = useState([]);
    const [isTransactionsListEmpty, setIsTransactionsListEmpty] = useState(false);

    const fetchTransactions = async (token) => {
        const apiURL = `${BACKEND_DOMAIN}/wallets/merchant/transactions`;

        try {
            const response = await axios.get(apiURL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const transactionsList = response.data.results;
            if (transactionsList.length === 0) {
                setIsTransactionsListEmpty(true);
            }
          //  setTransactions(transactionsList);
       
        // Format the updated_at field for each transaction
        const formattedTransactions = transactionsList.map(transaction => {
            const dateObj = new Date(transaction.created_at);
            const formattedDate = `${dateObj.toLocaleDateString('en-GB').replace(/\//g, '-')} ${dateObj.toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit'
            })}`;
            return { ...transaction, formatted_created_at: formattedDate };
        });

        setTransactions(formattedTransactions);
       
       
        } catch (e) {
            console.error("Error fetching Transactions -> ", e);
        }


    };

    useEffect(() => {
        fetchTransactions(authToken);
    }, [authToken]);

    const columns = useMemo(
        () => [
            {
                Header: "Transaction Code",
                accessor: "reference",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Sender",
                accessor: "sender_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Amount",
                accessor: "amount",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Transaction Fee",
                accessor: "fee",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Wallet Account",
                accessor: "wallet_account",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date",
                accessor: "formatted_created_at",
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    const breadcrumbItems = [
        { title: "Orders", link: "/" },
        { title: "Transactions", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Transactions" breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={transactions || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={isTransactionsListEmpty}
                                isEmptyContent="There are currently no transactions available"
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantTransactions;
