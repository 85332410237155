import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Alert, Input } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uploadProductRequest, uploadProductSuccess, uploadProductFailure } from "../../thunks";
import BACKEND_DOMAIN from "../../../../service";

const MerchantProductEdit = () => {
    const { productId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //console.log('the current product id is ',productId);

    const [product, setProduct] = useState({
        name: "",
        image: null,
        price: "",
        offer_price:"",
        category_id: "",
        subcategory_id: "",
        description: "",
    });

    const [categories, setCategories] = useState([]);
    const [subcategories, setSubCategories] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    // Adding/Updating product options
    const [showAccompanimentsFields, setShowAccompanimentsField] = useState(false);
    const [sidesList, setSidesList] = useState([{ accompaniment: "" }]);

    const handleAccompanimentsSelector = () => {
        setShowAccompanimentsField(!showAccompanimentsFields);
        if (sidesList.length > 1) {
            setSidesList([{ accompaniment: "" }]);
        } else if (sidesList.length === 1 && sidesList[0].accompaniment !== "") {
            setSidesList([{ accompaniment: "" }]);
        }
    };

    const handleAddSide = () => {
        setSidesList([...sidesList, { accompaniment: "" }]);
    };

    const handleRemoveSide = (index) => {
        const currentSidesList = [...sidesList];
        currentSidesList.splice(index, 1);
        setSidesList(currentSidesList);
    };

    const handleSideChange = (e, index) => {
        const { name, value } = e.target;

        const currentList = [...sidesList];
        currentList[index][name] = value;
        setSidesList(currentList);
    };

    const compileOptions = () => {
        let productOptions = [];
        sidesList.forEach((side) => {
            const toAdd = Object.values(side)[0];
            if (toAdd.trim() !== "") {
                productOptions.push(toAdd);
            }
        });
        return productOptions;
    };

    const token = useSelector((state) => state.Login.token);

    useEffect(() => {
        if (token && productId) {
            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/product/${productId}`;
            fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    // Update the state once the data is fetched
                    setProduct((product) => ({
                        ...product,
                        name: data.data.name,
                        image: null,
                        price: data.data.price,
                        offer_price: data.data.offer_price,
                        category_id: data.data.category_id,
                        subcategory_id: data.data.subcategory_id,
                        description: data.data.description,
                    }));
                })
                .catch((error) => console.error("Error fetching product details:", error));
        }
    }, [token, productId]);

    // Fetch categories on component mount
    useEffect(() => {
        if (token) {
            // Categories API URL
            const apiUrlCategories = `${BACKEND_DOMAIN}/business/merchant/categories`;

            // Fetch categories from the API
            fetch(apiUrlCategories, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => setCategories(data.categories))
                .catch((error) => console.error("Error fetching categories:", error));
        }
    }, [token]);

    // Fetch subcategories on component mount
    useEffect(() => {
        if (token) {
            // Categories API URL
            const apiUrlSubCategories = `${BACKEND_DOMAIN}/business/merchant/subcategories/list`;

            // Fetch categories from the API
            fetch(apiUrlSubCategories, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => setSubCategories(data.results))
                .catch((error) => console.error("Error fetching subcategories:", error));
        }
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // If the input is the category dropdown, store the id
        const categoryId = name === "category_id" ? value : product.category_id;

        // If the input is the subcategory dropdown, store the id
        // const subcategoryId = name === "subcategory_id" ? value : product.subcategory_id;

        // console.log("Before setProduct - Category ID:", categoryId, "Subcategory ID:", subcategoryId);

        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
            image: name === "image" ? e.target.files[0] : prevProduct.image,
        }));

        setSelectedCategory(categoryId);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const options = compileOptions();

        setIsLoading(true);

        try {
            dispatch(uploadProductRequest());

            const formData = new FormData();
            formData.append("name", product.name);
            formData.append("price", product.price);
            formData.append("offer_price", product.offer_price);
            formData.append("category_id", product.category_id);
            formData.append("subcategory_id", product.subcategory_id);
            formData.append("image", product.image);
            formData.append("description", product.description);
            formData.append("productOptions", options.length > 0 ? options : null);

            const apiurl = `${BACKEND_DOMAIN}/business/merchant/product/${productId}/update/`;
            const response = await fetch(apiurl, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Error editing product");
            }

            const data = await response.json();
            dispatch(uploadProductSuccess(data));

            // Show a success message:
            setSuccessMessage("Product Updated Successfully!");

            setTimeout(() => {
                navigate("/route-money/merchant/products");
            }, 2000);

            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (error) {
            dispatch(uploadProductFailure(error.message));
        }

        setIsLoading(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Edit Product"
                        breadcrumbItems={[
                            { title: "Products", link: "/products" },
                            { title: "Edit Product", link: "#" },
                        ]}
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Edit Product</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    <AvForm onValidSubmit={handleFormSubmit} enctype="multipart/form-data">
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="productName">
                                                        Product Name
                                                    </Label>
                                                    <AvField
                                                        name="name"
                                                        id="productName"
                                                        type="text"
                                                        placeholder="Enter Product Name"
                                                        errorMessage="Enter Product Name"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        value={product.name}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="productImage">
                                                        Product Image
                                                    </Label>
                                                    <AvField
                                                        name="image"
                                                        id="productImage"
                                                        type="file"
                                                        errorMessage="Select Product Image"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        //value={product.image}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="productPrice">
                                                        Product Price
                                                    </Label>
                                                    <AvField
                                                        name="price"
                                                        id="productPrice"
                                                        type="text"
                                                        placeholder="Enter Product Price"
                                                        errorMessage="Enter Product Price"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        value={product.price}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="offerPrice">
                                                        Offer Price
                                                    </Label>
                                                    <AvField
                                                        name="offer_price"
                                                        id="offerPrice"
                                                        type="text"
                                                        placeholder="Enter offer Price"
                                                        errorMessage="Enter offer price"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: false} }}
                                                        value={product.offer_price}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="productDescription">
                                                        Description
                                                    </Label>
                                                    <AvField
                                                        name="description" // Updated to match the API field name
                                                        id="productDescription"
                                                        type="text"
                                                        placeholder="Enter Product Description"
                                                        errorMessage="Enter Product Description"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        value={product.description}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="productCategory">
                                                        Category
                                                    </Label>
                                                    <AvField
                                                        type="select"
                                                        name="category_id"
                                                        id="productCategory"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        value={product.category_id}
                                                    >
                                                        <option value="">Select Category</option>
                                                        {categories.map((category) => {
                                                            return (
                                                                <option key={category.id} value={category.id}>
                                                                    {category.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="productSubCategory">
                                                        Sub-Category
                                                    </Label>
                                                    <AvField
                                                        type="select"
                                                        name="subcategory_id"
                                                        id="productSubCategory"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                        defaultValue={product.subcategory_id}
                                                    >
                                                        {selectedCategory === "" ? (
                                                            <option value="">Select Category First</option>
                                                        ) : (
                                                            <>
                                                                <option value="">Select Sub-Category</option>
                                                                {subcategories
                                                                    .filter(
                                                                        (subcategory) =>
                                                                            subcategory.category ===
                                                                            parseInt(selectedCategory)
                                                                    )
                                                                    .map((subcategory) => (
                                                                        <option
                                                                            key={subcategory.id}
                                                                            value={subcategory.id}
                                                                        >
                                                                            {subcategory.name}
                                                                        </option>
                                                                    ))}
                                                            </>
                                                        )}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col className="mt-3 mb-5">
                                                <div className=" d-flex align-items-center">
                                                    <Label className="form-label me-3" htmlFor="productAccompaniments">
                                                        Add Accompaniments
                                                    </Label>
                                                    <AvField
                                                        type="checkbox"
                                                        name="accompaniments"
                                                        id="productAccompaniments"
                                                        onChange={handleAccompanimentsSelector}
                                                        value={showAccompanimentsFields}
                                                        style={{ fontSize: 21 }}
                                                    />
                                                    <p className="ms-3 mt-2">{`(Check to Add, or Uncheck to Remove Accompaniments.)`}</p>
                                                </div>

                                                {showAccompanimentsFields &&
                                                    sidesList.map((side, index) => (
                                                        <div key={index} className="d-flex">
                                                            <div>
                                                                <Input
                                                                    name="accompaniment"
                                                                    id="productName"
                                                                    type="text"
                                                                    placeholder="Accompaniment Name"
                                                                    className="form-control mb-3"
                                                                    // value={side.accompaniment}
                                                                    onChange={(e) => handleSideChange(e, index)}
                                                                    required
                                                                    style={{ minWidth: "200px" }}
                                                                />
                                                                {sidesList.length - 1 === index && (
                                                                    <Button
                                                                        outline
                                                                        color="success"
                                                                        type="button"
                                                                        onClick={handleAddSide}
                                                                    >
                                                                        Add New Entry
                                                                    </Button>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    outline
                                                                    color="danger"
                                                                    type="button"
                                                                    style={{ height: "38px", marginLeft: 16 }}
                                                                    onClick={() => handleRemoveSide(index)}
                                                                    disabled={sidesList.length === 1}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </Col>
                                        </Row>
                                        <Row className="d-flex justify-content-end pe-2">
                                            <Button
                                                color="primary"
                                                type="submit"
                                                disabled={isLoading}
                                                style={{ width: "fit-content" }}
                                            >
                                                {isLoading ? (
                                                    <>
                                                        <span
                                                            className="spinner-border spinner-border-sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                        <span className="ms-2">Updating..</span>
                                                    </>
                                                ) : (
                                                    "Update Product"
                                                )}
                                            </Button>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantProductEdit;
