import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BACKEND_DOMAIN from "../../service";

const Logout = () => {
    const apiUrl = `${BACKEND_DOMAIN}/admin/merchants/logout/`;

    const navigate = useNavigate();

    useEffect(() => {
        const logoutUser = async () => {
            try {
                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                });

                if (!response.ok) {
                    throw new Error("Logout failed");
                }

                // Redirect to login page or any other page after successful logout
                navigate("/route-money/merchant/login");
            } catch (error) {
                // Handle errors
                console.error("Logout error:", error);
            }
        };

        logoutUser();
    }, [navigate]);

    return <h1>&nbsp;</h1>;
};

export default Logout;
