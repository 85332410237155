import React, { useState, useEffect } from 'react';

// Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Container, Row } from "reactstrap";
import MiniWidgets from "../../../Dashboard/MiniWidgets";
import { useSelector } from 'react-redux';
import axios from 'axios';

const RevenueReport = () => {
  // Define reports for Admins:

  // Fetch reports data
  const token = useSelector((state) => state.Login.token);
  const [reportData, setReportData] = useState(null); // Initialize as null to check if data is loaded

  useEffect(() => {
    if (token) {
      const fetchReports = async () => {
        try {
          const response = await axios.get('https://backoffice.route.money/api/v1/business/report/data/', {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          setReportData(response.data);
        } catch (error) {
          console.error("Error fetching reports:", error);
        }
      };

      fetchReports();
    }
  }, [token]);

  const superUserReports = [
    {
      icon: "ri-stack-line",
      title: "Transactions Volume",
      value: `KES ${reportData ? reportData.total_value : 0}`,
      rate: "2.4%",
      desc: "From previous month",
      routeTo: "#",
    },
    {
      icon: "ri-shopping-cart-2-line",
      title: "Total Orders",
      value: `${reportData ? reportData.total_orders : 0}`,
      rate: "2.4%",
      desc: "From previous month",
      routeTo: "",
    },
    {
      icon: "ri-user-line",
      title: "Total Cashback Value",
      value: `${reportData ? reportData.cashback : 0}`,
      rate: "2.4%",
      desc: "From previous month",
      routeTo: "",
    },
  ];

  const breadcrumbItems = [
    { title: "Reports", link: "/" },
    { title: "Revenue Reports", link: "#" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Revenue Reports" breadcrumbItems={breadcrumbItems} />
          <Row className="pb-5">
            <MiniWidgets reports={superUserReports} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RevenueReport;
