import React, { useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input, Row, Col } from "reactstrap";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "../Utilities/canvasPreview"; // Ensure you have this utility function
import { useDebounceEffect } from "../Utilities/useDebounceEffect"; // Ensure you have this custom hook

const EditProfileForm = ({ onCancel, onSave, userData }) => {
    const [updatedData, setUpdatedData] = useState({
        profile_image: null,
        cover_image: null,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [crop, setCrop] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageType, setSelectedImageType] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [scale, setScale] = useState(1);

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const aspect = selectedImageType === "profile_image" ? 1 : 16 / 9;

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgSrc(reader.result.toString() || "");
                setSelectedImageType(name);
                setModalOpen(true); // Open the crop modal
            });
            reader.readAsDataURL(file);
        }
    };

    const handleSaveCrop = () => {
        const canvas = previewCanvasRef.current;
        canvas.toBlob((blob) => {
            setUpdatedData((prevData) => ({
                ...prevData,
                [selectedImageType]: blob, // Set the blob to the appropriate image type (profile or cover)
            }));
            setModalOpen(false);
            setCompletedCrop(null); // Reset the crop after saving
        });
    };

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                await canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale);
            }
        },
        100,
        [completedCrop, scale]
    );

    const handleSave = async () => {
        setIsLoading(true);
        try {
            await onSave(updatedData);
        } catch (error) {
            console.error("Error saving profile:", error);
        } finally {
            setIsLoading(false);
            onCancel(); // Close the modal after saving
        }
    };

    return (
        <>
            <Modal isOpen={true} role="document" autoFocus={true} centered={true} className="composemodal" tabIndex="-1">
                <div className="modal-content">
                    <ModalHeader>Edit Profile</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="profile_image">Profile Photo</Label>
                            <Input
                                type="file"
                                className="form-control"
                                id="profile_image"
                                name="profile_image"
                                onChange={handleFileChange}
                            />
                            <Label for="cover_image" className="mt-3">Cover Photo</Label>
                            <Input
                                type="file"
                                className="form-control"
                                id="cover_image"
                                name="cover_image"
                                onChange={handleFileChange}
                            />
                        </FormGroup>
                    </ModalBody>
                    <div className="modal-footer">
                        <Button color="secondary" onClick={onCancel} disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit" onClick={handleSave} disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="ms-2">Updating...</span>
                                </>
                            ) : (
                                "Save Changes"
                            )}
                        </Button>
                    </div>
                </div>
            </Modal>

            {/* Crop Modal */}
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
                    Crop {selectedImageType === "profile_image" ? "Profile" : "Cover"} Image
                </ModalHeader>
                <ModalBody>
                    {imgSrc && (
                        <Row>
                            <Col md="6">
                                <ReactCrop
                                    crop={crop}
                                    onChange={(newCrop) => setCrop(newCrop)}
                                    onComplete={(newCrop) => setCompletedCrop(newCrop)}
                                    aspect={aspect}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        onLoad={() => {
                                            setCrop({ unit: "%", width: 50, aspect });
                                        }}
                                        style={{ maxWidth: "100%", maxHeight: "400px" }}
                                    />
                                </ReactCrop>
                            </Col>
                            <Col md="6">
                                {completedCrop && (
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: "1px solid black",
                                            width: "100%",
                                            maxHeight: "400px",
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                    )}
                </ModalBody>
                <div className="modal-footer">
                    <Button color="secondary" onClick={() => setModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSaveCrop}>
                        Crop & Save
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default EditProfileForm;