import React, { useState, useEffect, useRef } from "react";
import "firebase/compat/auth";

import { Row, Col, Button, Alert, Container, Label,Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Slider,MenuItem, FormControl, InputLabel } from '@mui/material';
import { Card, CardBody, TabContent, TabPane, NavItem, NavLink, Input, Form } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";
import { useSelector } from 'react-redux';
import logodark from "../../../assets/images/logo-dark.png";
import logolight from "../../../assets/images/logo-light-new.jpg";
import "../../../assets/css/Error.css";
import { registerUserStart, registerUserSuccessful, registerUserFailed } from "../../../store/actions";
import validateRequiredFields from "../../Authentication/utilities/registrationFormValidation";
import Select from "react-select";
import businessTypes from "../../Authentication/utilities/businessTypes";
import { specifiedBusinessTypes } from "../../Authentication/utilities/businessTypes";
import { fetchTable } from "../../Reservation/View/utilities/tabledata";
import BACKEND_DOMAIN from "../../../service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// React PhoneInput custom styles
const customInputStyle = {
    width: "100%",
    height: "40px",
    backgroundColor: "inherit",
    border: "1px solid #cacaca",
    marginTop: "50px",
};

const customDropdownStyle = {
    backgroundColor: "inherit",
    borderRadius: 0,
    boxShadow: "none",
    border: "1px solid transparent",
    color: "inherit",
    height: "fit-content",
    marginTop: 0,
    marginLeft: "-2px",
};

// React Select input field Custom styles
const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "inherit",
        borderColor: "transparent",
        color: "inherit",
        margin: "-7.5px -12px",
        ":hover": {
            borderColor: "transparent",
        },
    }),
    placeholder: (styles) => ({
        ...styles,
        color: "inherit",
    }),
    input: (styles) => ({
        ...styles,
        color: "inherit",
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: "inherit",
        margin: "8px -14px",
        width: "101%",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled ? null : isSelected ? "inherit" : isFocused ? "#5664d2" : null,
            color: isDisabled ? null : isSelected ? "#5664d2" : "inherit",
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled ? (isSelected ? "#5664d2" : "#5664d2") : null,
            },
        };
    },
};

// const ProfileEdit = (props) => {
    const ProfileEdit = (userData) => {
    const token = useSelector((state) => state.Login.token);

    const [registrationError, setRegistrationError] = useState(null);
    const [userRegistrationSuccessful, setUserRegistrationSuccessful] = useState(false);
    const [userRegistrationfailure, setUserRegistrationFailure] = useState('');
    const [registrationFailedErrorMessage, setRegistrationFailedErrorMessage] = useState(null);

    const [errorMessage, setErrorMessage] = useState('');
    const [successfulMessage, setsucccessfulMessage] = useState('');


    // Loading spinners for said actions:
    const [isSubmittingRegistration, setIsSubmittingRegistration] = useState(false);
    const [errors, setErrors] = useState({});
    const [modalOpen, setModalOpen] = useState(true);


    // fetch business data

    // Define the fetchUserData function
 
    // end fetch buzz data

    const [allFormData, setAllFormData] = useState({
            contact_person: userData ? userData.userData.contact_person: "",
            name:userData ? userData.userData.name: "",
            first_name:userData ? userData.userData.first_name: "",
            last_name: userData ? userData.userData.last_name: "",
            location:userData ? userData.userData.location: "",
            specific_type:userData ? userData.userData.specific_type: "",
            type:userData ? userData.userData.type: "",
    });

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const [activeTabProgress, setActiveTabProgress] = useState(1);
    // eslint-disable-next-line
    const [progressValue, setProgressValue] = useState(25);
    // const formRef = useRef(null);
  

    // Temp value holder for the business type:
    const [selectedBusinessType, setSelectedBusinessType] = useState(null);
    //const [selectedSpecBusinessType, setSelectedSpecBusinessType] = useState(null);
    const [selectedSpecBusinessType, setSelectedSpecBusinessType] = useState(
        businessTypes.find(option => option.value === allFormData?.specific_type) || null
    );
    const [specBusinessTypeOptions, setSpecBusinessTypeOptions] = useState([]);

   
    const [selectedRole, setSelectedRole] = useState([]);
    const [rolePermissions, setRolePermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    // Tables
    const [allTableList, setAllTableList] = useState([]);
    const [tableListEmpty, setTableListEmpty] = useState(false);
    const [selectedTables, setSelectedTables] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({});

    const business_id= userData.userData.business;
      const toggleModal = () => setModalOpen(!modalOpen);


    //   const onBusinessTypeChange = (selectedBusinessType) => {
    //     setSelectedBusinessType(selectedBusinessType);

    //     //Find index of selected category:
    //     const businessTypeIndex = specifiedBusinessTypes.findIndex(
    //         (bType) => Object.keys(bType)[0] === `${selectedBusinessType.label}`
    //     );
    //     setSpecBusinessTypeOptions(specifiedBusinessTypes[businessTypeIndex][selectedBusinessType.label]);

    //     setSelectedSpecBusinessType(null);
    //     setAllFormData((prevState) => ({
    //         ...prevState,
    //         pane4: {
    //             ...prevState.pane4,
    //             specific_type: "",
    //         },
    //     }));

    //     setAllFormData((prevState) => ({
    //         ...prevState,
    //         pane4: {
    //             ...prevState.pane1,
    //             type: selectedBusinessType.value,
    //         },
    //     }));
    // };

    const onBusinessTypeChange = (selectedBusinessType) => {
        setSelectedBusinessType(selectedBusinessType);
    
        // Find index of selected category
        const businessTypeIndex = specifiedBusinessTypes.findIndex(
            (bType) => Object.keys(bType)[0] === `${selectedBusinessType.label}`
        );
        setSpecBusinessTypeOptions(specifiedBusinessTypes[businessTypeIndex][selectedBusinessType.label]);
    
        setSelectedSpecBusinessType(null);
    
        // Update allFormData directly
        setAllFormData((prevState) => ({
            ...prevState,
            specific_type: "", // Assuming you want to clear specific_type
            type: selectedBusinessType.value,
        }));
    };
    

    // const onSpecBusinessTypeChange = (selectedSpecBusinessType) => {
    //        setSelectedSpecBusinessType(selectedSpecBusinessType);

    //     setAllFormData((prevState) => ({
    //         ...prevState,
    //         pane1: {
    //             ...prevState.pane4,
    //             specific_type: selectedSpecBusinessType.value,
    //         },
    //     }));
    // };

       // Update selectedSpecBusinessType when userData changes
       useEffect(() => {
        if (allFormData?.specific_type) {
            setSelectedSpecBusinessType(
                businessTypes.find(option => option.value === allFormData.specific_type) || null
            );
        }
    }, [userData, businessTypes]);

    const onSpecBusinessTypeChange = (selectedSpecBusinessType) => {
        setSelectedSpecBusinessType(selectedSpecBusinessType);

        // Update allFormData directly
        setAllFormData((prevState) => ({
            ...prevState,
            specific_type: selectedSpecBusinessType ? selectedSpecBusinessType.value : "",
        }));
    };

    // const onSpecBusinessTypeChange = (selectedSpecBusinessType) => {
    //     setSelectedSpecBusinessType(selectedSpecBusinessType);
    
    //     // Update allFormData directly
    //     setAllFormData((prevState) => ({
    //         ...prevState,
    //         specific_type: selectedSpecBusinessType ? selectedSpecBusinessType.value : "",
    //     }));
    // };
    

    const toggleTab = (tab) => {
            if (activeTab !== tab) {
                if (tab >= 1 && tab <= 2) {
                    setActiveTab(tab);
                    toggleTabProgress(tab);
                }
            }
    
    };

    const toggleTabProgress = (tab) => {
        if (activeTabProgress !== tab) {
            if (tab >= 1 && tab <= 2) {
                setActiveTabProgress(tab);

                if (tab === 1) {
                    setProgressValue(50);
                }
                if (tab === 2) {
                    setProgressValue(100);
                }
              
            }
        }
    };


const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAllFormData((prevState) => ({
        ...prevState,
        [name]: value, // Update directly in allFormData
    }));
};



// Handler function

const handleValidSubmit = async (event) => {
    event.preventDefault();
    setIsSubmittingRegistration(true);

        try {
        const apiUrl = `https://backoffice.route.money/api/v1/admin/business/update/${business_id}`;

            const formDataToSend = {
                business_type: allFormData.businesstype,
                location: allFormData.location,
                name: allFormData.name,
                first_name: allFormData.first_name,
                last_name: allFormData.last_name,
                type:allFormData.type,
                contact_person:allFormData.contact_person,   
            };

         // Stringify the extracted data
        const jsonData = JSON.stringify(formDataToSend);

        console.log('token ',token)
        // Log the JSON data being sent
        const response = await fetch(apiUrl, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`, // Assuming you have the token available
            },
            body: jsonData,
        });


        const responseData = await response.json();
        // Check if the response is ok
        if (!response.ok) {

            setTimeout(() => {
                setErrorMessage("Update failed ",responseData.error);
            }, 3000);

            setModalOpen(false);
            throw new Error('Network response was not ok');
        }else{
              
            setTimeout(() => {
                setsucccessfulMessage(responseData.message);
                //window.location.reload();
            }, 4000);
            
          setModalOpen(false);
        }
  
        } catch(error){
            console.log('error occured');
             setTimeout(() => {
            setErrorMessage("Update failed!!");
        }, 3000);

            setModalOpen(false);
        }
}
    return (
        <React.Fragment> 
                    {/* <div className="pt-3 pb-3">
                        <Container fluid={true} className="vh-100 d-flex justify-content-center align-items-center"> */}
                        <div className="page-content">
                        <Container fluid={true}>
                            <Row className="d-flex justify-content-center">
                            
                            {successfulMessage && <Alert color="success">{successfulMessage}</Alert>}
                            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                        <Col xs="12" sm="8" md="10" lg="12">
                            <Card>
                                <CardBody>
                                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                       
                                    
                                                <Form>
                                        

                                                        {/* form modal */}

                                                        <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                                                        <ModalHeader toggle={toggleModal}>Update profile</ModalHeader>
                                                        <ModalBody> 
                                                        <Row>

                                                        <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="email">
                                                                Name{" "}
                                                            </Label>
                                                            <Input
                                                                name="name"
                                                                value={allFormData.name} // Access the email directly
                                                                onChange={handleInputChange}
                                                                type="text"
                                                                className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                                                id="name"
                                                                placeholder="Enter Name"
                                                            />
                                                            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                                                        </div>
                                                    </Col>

                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="email">
                                                                Contact Person{" "}
                                                            </Label>
                                                            <Input
                                                                name="contact_person"
                                                                value={allFormData.contact_person} // Access the email directly
                                                                onChange={handleInputChange}
                                                                type="text"
                                                                className={`form-control ${errors.contact_person ? "is-invalid" : ""}`}
                                                                id="contact_person"
                                                                placeholder="Enter contact person name"
                                                            />
                                                            {errors.contact_person && <div className="invalid-feedback">{errors.email}</div>}
                                                        </div>
                                                    </Col>
                                                      
                                                  </Row>
                                                  <Row>
                                                  <Col lg="6">
                                                        <div className="mb-3">
                                                        <Label className="form-label" htmlFor="first_name">
                                                                    First Name{" "}
                                                                 
                                                                </Label>
                                                            <Input
                                                                name="first_name"
                                                                value={allFormData.first_name} // Access the email directly
                                                                onChange={handleInputChange}
                                                                type="text"
                                                                className={`form-control ${errors.contact_person ? "is-invalid" : ""}`}
                                                                id="first_name"
                                                                placeholder="Enter first name"
                                                            />
                                                            {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                                                        </div>
                                                    </Col>
                                
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                        <Label className="form-label" htmlFor="last_name">
                                                                    Last name{" "}
                                                                   
                                                                </Label>
                                                            <Input
                                                                name="last_name"
                                                                value={allFormData.last_name} // Access the email directly
                                                                onChange={handleInputChange}
                                                                type="text"
                                                                className={`form-control ${errors.last_name ? "is-invalid" : ""}`}
                                                                id="last_name"
                                                                placeholder="Enter last name"
                                                            />
                                                            {errors.last_name && <div className="invalid-feedback">{errors.first_name}</div>}
                                                        </div>
                                                    </Col>

                                                   </Row>


                                                        <Row>
                                                            {/* <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>
                                                                        General Business Type{" "}
                                                                   
                                                                    </Label>

                                                                    <Select
                                                                        placeholder="Select or Type to filter..."
                                                                        options={businessTypes}
                                                                        value={selectedBusinessType}
                                                                        onChange={onBusinessTypeChange}
                                                                        className={`form-control ${errors.type ? "is-invalid" : ""}`}
                                                                        styles={colourStyles}
                                                                    />
                                                                     {errors.type && <div className="invalid-feedback">{errors.type}</div>}
                                                                </div>
                                                            </Col> */}

                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>
                                                                        General Business Type{" "}
                                                                    </Label>

                                                                    <Select
                                                                        placeholder="Select or Type to filter..."
                                                                        options={businessTypes}
                                                                        value={businessTypes.find(option => option.value === allFormData.type)} // Ensure correct value mapping
                                                                        onChange={onBusinessTypeChange}
                                                                        className={`form-control ${errors.type ? "is-invalid" : ""}`}
                                                                        styles={colourStyles}
                                                                    />
                                                                    {errors.type && <div className="invalid-feedback">{errors.type}</div>}
                                                                </div>
                                                            </Col>


                                                            {/* <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>
                                                                        Specific Business Type{" "}
                                                                    
                                                                    </Label>

                                                                    <Select
                                                                        placeholder="Select or Type to filter..."
                                                                        options={
                                                                            !selectedBusinessType
                                                                                ? [
                                                                                      {
                                                                                          value: "",
                                                                                          label: "Select General Type First",
                                                                                      },
                                                                                  ]
                                                                                : specBusinessTypeOptions
                                                                        }
                                                                        value={selectedSpecBusinessType}
                                                                        onChange={onSpecBusinessTypeChange}
                                                                        className={`form-control ${errors.specific_type ? "is-invalid" : ""}`}
                                                                        styles={colourStyles}
                                                                    />
                                                                      {errors.specific_type && <div className="invalid-feedback">{errors.specific_type}</div>}
                                                                </div>
                                                            </Col> */}

                                                                <Col lg="6">
                                                                    <div className="mb-3">
                                                                        <Label>
                                                                            Specific Business Type{" "}
                                                                        </Label>

                                                                        <Select
                                                                            placeholder="Select or Type to filter..."
                                                                            options={
                                                                                !selectedBusinessType
                                                                                    ? [{ value: "", label: "Select General Type First" }]
                                                                                    : specBusinessTypeOptions
                                                                            }
                                                                            value={selectedSpecBusinessType}
                                                                            onChange={onSpecBusinessTypeChange}
                                                                            className={`form-control ${errors.specific_type ? "is-invalid" : ""}`}
                                                                            styles={colourStyles}
                                                                        />
                                                                        {errors.specific_type && <div className="invalid-feedback">{errors.specific_type}</div>}
                                                                    </div>
                                                                </Col>

                
                                                        </Row>
                                                        <Row>
                                                        <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="email">
                                                                Location{" "} 
                                                            </Label>
                                                            <Input
                                                                name="location"
                                                                value={allFormData.location} // Access the email directly
                                                                onChange={handleInputChange}
                                                                type="text"
                                                                className={`form-control ${errors.location ? "is-invalid" : ""}`}
                                                                id="email"
                                                                placeholder="Enter location"
                                                            />
                                                            {errors.location && <div className="invalid-feedback">{errors.location}</div>}
                                                        </div>
                                                    </Col>

                                                    </Row>
                                            

                                                   </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="success" onClick={handleValidSubmit}>Update</Button>
                                                    </ModalFooter>
                                                </Modal>
                                                        {/* end form modal */}

                                                    

                                                
                                                </Form>

                                        
                                    </div>
                                </CardBody>
                            </Card>
                         
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
};

export default connect(mapStateToProps)(ProfileEdit);
