import React, { useState, useEffect,useRef } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Alert,InputGroup, InputGroupAddon, InputGroupText,Modal,ModalHeader,ModalBody,ModalFooter } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
// import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { orderProductRequest, orderProductSuccess, orderProductFailure } from "../../../Orders/thunks";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import BACKEND_DOMAIN from "../../../../service";
import {
    fetchProductsOrdersAndBranches,
    fetchProductOffersData,
    fetchCashbackOffersData,
    fetchWalletBalanceData
} from '../../../Dashboard/data_fetching/dashboardData';
import axios from "axios";


const SEND_OTP_URL = `${BACKEND_DOMAIN}/users/generate/pin`;
const VERIFY_OTP_URL = `${BACKEND_DOMAIN}/users/verify-otp`;


// React PhoneInput custom styles
const customInputStyle = {
    width: "100%",
    height: "40px",
    backgroundColor: "inherit",
    border: "1px solid #cacaca",
    marginTop: "50px",
};

const customDropdownStyle = {
    backgroundColor: "inherit",
    borderRadius: 0,
    boxShadow: "none",
    border: "1px solid transparent",
    color: "inherit",
    height: "fit-content",
    marginTop: 0,
    marginLeft: "-2px",
    
};

const Index = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.Login.token);
    const [userData, setUserData] = useState({});
    const [walletBalance, setWalletBalance] = useState(0);
    const [modal, setModal]=useState(false)

    const toggle = () => setModal(!modal);
    // fetch current ballance
    // f3erching global data
    const setLedger = async (token) => {
        try {
            const [
                walletBalanceData
            ] = await Promise.all([
                fetchWalletBalanceData(token)
            ]);
            setWalletBalance(walletBalanceData.ledger_balance); // Ensure correct access to ledger_balance
        }catch(e){
      console.log("failed to fetch data ",e);
        }
    };
    
    useEffect(() => {
        setLedger(token);
    }, [token]);
    // end fetch

        // Define the fetchUserData function
        const fetchUserData = async () => {
            try {
                const apiUrl = `${BACKEND_DOMAIN}/business/merchant/detail/`;
                const response = await axios.get(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
    
                // Set the fetched user data in the state
                setUserData(response.data);
    
                // userName = capitalizeFirstLetter(userData.name);
            } catch (error) {
                console.error("Error fetching user details:", error.message);
            }
        };
    
        // Use useEffect to fetch user details from the API when the component mounts
        useEffect(() => {
            fetchUserData();
        }, [token]); // The dependency array ensures that the effect runs only when authToken changes

    

    const [withdrawdata, setWithdrawData] = useState({
        mode: "",
        phone_number:userData.phone_number,
        bankName: "",
        provider_id:"",
        accountNumber:"",
        amount:"",
        otp:"",
    });

    const [banks, setBanks] = useState([]);
    const [products, setProducts]=useState([]);
    const [successMessage, setSuccessMessage] = useState("");

    // otp verification
    const [otp, setOtp] = useState("");
    const [otpSentMessage, setOtpSentMessage] = useState("");
    const [otpVerifiedMessage, setOtpVerifiedMessage] = useState("");
    const [otpVerificationSuccessful, setOtpVerificationSuccessful] = useState(false);
    const [isFieldsValid, setIsFieldsValid] = useState(true);
   

    
    // Loading spinners for said actions:
    const [isSendingOTP, setIsSendingOTP] = useState(false);
    const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);

     // OTP sending and verifying error Message:
     const [otpNotSentError, setOtpNotSentError] = useState(null);
     const [otpVerificationError, setOtpVerificationError] = useState(null);
     const [resetTimer, setResetTimer] = useState(0);



    const [isLoading, setIsLoading] = useState(false);
    const [withdrawMode, setWithdrawMode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [unsuccessfulMessage, setUnsucccessfulMessage] = useState('');

    //     const [showPin, setShowPin] = useState(false);

     // Sending OTP
     const sendOTP = async () => {
        const phone_number='+254740782174';
       // const { phone_number, email } = allFormData.pane1;

        if (phone_number === "") {
            setOtpNotSentError("Email and Phone number added are INVALID.");
            setTimeout(() => {
                setOtpNotSentError(null);
            }, 3000);

            return;
        }

        setIsSendingOTP(true);


            try {
                const res = await axios.post(SEND_OTP_URL, {
                    phone_number: token
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization":`Bearer ${token}`,
                    },
                });
    

            setOtpSentMessage(res.data["message"]);

            setTimeout(() => {
                setOtpSentMessage(null);
            }, 3000);
        } catch (e) {
            setOtpNotSentError("An Error occurred.");
            setTimeout(() => {
                setOtpNotSentError(null);
            }, 3000);
        } finally {
            setIsSendingOTP(false);
        }
    };

    // Resend otp:
    const startTimer = () => {
        setResetTimer(30);

        const interval = setInterval(() => {
            setResetTimer((prev) => {
                if (prev === 1) {
                    clearInterval(interval); // Clear interval when timer reaches 0
                }
                return prev - 1;
            });
        }, 1000);
    };

    const resendOTPWithTimer = async () => {  
        await sendOTP();
        setModal(!modal);

        startTimer();
    };


    useEffect(() => {
        if (token) {
        const banksApi = `https://backoffice.route.money/api/v1/wallets/merchant/providers`;
            fetch(banksApi, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => setBanks(data.data.providers))
                .catch((error) => console.error("Error fetching banks:", error));
        }

    }, [token]);


    
    const handleInputChange = (e) => {
        const { name, value } = e.target ? e.target : { name: 'phone_number', value: e };
    
        if (name === 'bankName') {
            const selectedBank = banks.find(bank => bank.providerName === value);
            if (selectedBank) {
                setWithdrawData((prevwithdrawdata) => ({
                    ...prevwithdrawdata,
                    [name]: value,
                    provider_id: selectedBank.providerName // Set provider_id based on selected bank
                }));
            }
        } else {
            setWithdrawData((prevwithdrawdata) => ({
                ...prevwithdrawdata,
                [name]: value
            }));
        }

        if (name === 'amount') {
            if (parseFloat(value) > walletBalance) {
                setErrorMessage(`The wallet balance is lower than the entered amount. Your wallet balance is ${walletBalance}`);
            } else {
                setErrorMessage('');
            }
        }


    };
    



   // console.log("banks are ",banks);

    const handleWithdrawModeChange = (event) => {
        setWithdrawMode(event.target.value);
        handleInputChange(event);
        setWithdrawData({
            ...withdrawdata,
            mode: withdrawMode,
             phone_number:userData.phone_number, // Reset phone number when mode changes
            bankName: '', // Reset bank name when mode changes
            provider_id: '', // Reset provider id when mode changes
            accountNumber: '', // Reset account number when mode changes
            amount: '', // Reset mpesa amount when mode changes,
            otp:'',
        });
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        console.log('withraw entered');

        setIsLoading(true);

        try {
            dispatch(orderProductRequest());
            const withdrawPayload = {
                mode: withdrawMode,
                beneficiary_account: withdrawMode === "mpesa" ? userData.phone_number : withdrawdata.accountNumber,
                provider_id:withdrawdata.provider_id, // Reset provider id when mode changes
                provider: withdrawMode === "mpesa" ? "MPESA WALLET" : withdrawdata.bankName, // Reset account number when mode changes
                amount:withdrawdata.amount,
                otp:withdrawdata.otp,
            };

         //console.log('withdraw payload ',withdrawPayload)
          
            const apiurl = `https://backoffice.route.money/api/v1/wallets/transactions`;
            const response = await fetch(apiurl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body:JSON.stringify({
                    amount: withdrawdata.amount,
                    beneficiary_account: withdrawMode === "mpesa" ? userData.phone_number : withdrawdata.accountNumber,
                    pin:withdrawdata.otp,
                    narration: "Payment",
                    provider: withdrawMode === "mpesa" ? "MPESA WALLET" : withdrawdata.bankName,
                    beneficiary_reference: "013364321617653"
                }),
            });

           
    const data = await response.json();

    if (!response.ok) {
        setModal(!modal);
        console.log('Transaction error response:', data);
        setUnsucccessfulMessage('Transaction failed,',data.errors)
        throw new Error(data.message || "Transaction unsuccessful, please try again");
    }{
        setModal(!modal);
        if(data.json="success"){
            setSuccessMessage(`ksh ${withdrawdata.amount} successfully transferred !`);
        }else{
            setUnsucccessfulMessage('Failure ',data.message)
        }
     

        setWithdrawMode("");
    }
            dispatch(orderProductSuccess(data));

            // Show a success message:
         
       
            // setTimeout(() => {
            //     navigate("/route-money/withdraw");
            // }, 2000);

            setTimeout(() => {
                setSuccessMessage("");
            }, 3000);
        } catch (error) {
            setModal(!modal);
            setUnsucccessfulMessage('Transaction failed, Please try again')
            dispatch(orderProductFailure(error.message));
        }

        setIsLoading(false);
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Withdraw Cash"
                        breadcrumbItems={[
                            { title: "Withdraw", link: "/route-money/withdraw" },
                            { title: "Withdraw cash", link: "#" },
                        ]}
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Withdraw Cash</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    {unsuccessfulMessage && <Alert color="danger">{unsuccessfulMessage}</Alert>}
                                    <AvForm onValidSubmit={handleFormSubmit} enctype="multipart/form-data">
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="product">
                                                        Withdraw To
                                                    </Label>
                                                    <AvField
                                                        type="select"
                                                        name="mode"
                                                        id="mode"
                                                        onChange={handleWithdrawModeChange}
                                                        validate={{ required: { value: true } }}
                                                    >
                                                        <option value="">Select Mode</option>
                                                        <option value="mpesa">M-Pesa</option>
                                                        <option value="Bank">Bank</option>
                                                    </AvField>
                                                </div>
                                            </Col>
                                            {withdrawMode === 'mpesa' && (
                    <>
                      

                        <Col md="6">
                        <div className="mb-3">
                            <Label className="form-label" htmlFor="phone_number">
                                Business Phone Number
                            </Label>
                            <input
                                type="text"
                                id="phone_number"
                                className="form-control"
                                value={userData.phone_number}
                                readOnly
                                style={{
                                    backgroundColor: '#f1f1f1', // Slightly different background to indicate read-only
                                    color: '#495057' // Text color to match form controls
                                }}
                            />
                        </div>
                    </Col>


                    
                        </>
                )}
                     
                     {/* bank option */}
                     {withdrawMode === 'Bank' && (
                    <>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label" htmlFor="bankName">
                                    Bank Name
                                </Label>
                                <AvField
                                    type="select"
                                    name="bankName"
                                    value={withdrawdata.bankName}
                                    id="bankName"
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                >
                                    <option value="">Select Bank</option>
                                    {banks && banks.map(bank => (
                                        <option key={bank.providerName} value={bank.providerName}>
                                            {bank.providerName}
                                        </option>
                                    ))}
                                </AvField>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label" htmlFor="accountNumber">
                                    Account Number
                                </Label>
                                <AvField
                                    name="accountNumber"
                                    id="accountNumber"
                                    value={withdrawdata.accountNumber}
                                    type="text"
                                    placeholder="Account number"
                                    errorMessage="Enter Account Number"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                />
                            </div>
                        </Col>
                      
                    </>
                )}

                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label" htmlFor="mpesaAmount">
                                    Amount
                                </Label>
                                <AvField
                                    name="amount"
                                    id="amount"
                                    type="number"
                                    placeholder="Amount"
                                    value={withdrawdata.amount}
                                    errorMessage="Enter Amount"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                />
                                  {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                            </div>
                        </Col>

                           {/* set up modal */}
                                                         
                           <Modal isOpen={modal} toggle={toggle} className="custom-modal">
                                        <ModalHeader>Verify OTP</ModalHeader>
                                        <ModalBody>
                                            <div className="d-flex justify-content-center align-items-center">
                                            <AvField
                                                name="otp"
                                                onChange={handleInputChange}
                                                type="text"
                                                className="form-control"
                                                id="otp"
                                                value={withdrawdata.otp}
                                                placeholder="Enter OTP"
                                                style={{ width: '100%' }}
                                            />
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="secondary" onClick={toggle}>
                                            Close
                                            </Button>
                                            <Button color="success" onClick={handleFormSubmit}>
                                            {isLoading ? (
                                                <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span className="ms-2">Processing...</span>
                                                </>
                                            ) : (
                                                "Withdraw"
                                            )}
                                            </Button>
                                        </ModalFooter>
                                        </Modal>

                                                            {/* end of modal */}
               
                        {isFieldsValid && (
                        <Col md="6">
                            <div className="mb-3">
                              
            
                              <div className="d-flex align-items-center mb-2">
                           
                                
                                </div>
                            {otpVerifiedMessage && (
                                <Alert color="success">
                                    {otpVerifiedMessage}
                                </Alert>
                            )}
                            </div>
                        </Col>
)}
                                            
                                        </Row>
                                   
                                   {/* put the otp verifier */}
                                   
                                   <Button
                                    type="button"
                                    color="primary"
                                    onClick={resendOTPWithTimer}
                                    className="ms-2"
                                    disabled={isSendingOTP || resetTimer > 0}
                                >
                                    {isSendingOTP ? (
                                        <>
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            <span className="ms-2">
                                                Sending OTP...
                                            </span>
                                        </>
                                    ) : resetTimer > 0 ? (
                                        `Resend in: ${resetTimer}s`
                                    ) : (
                                        "Proceed"
                                    )}
                                </Button>
                                
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Index;
