import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import withRouter from "../../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import BACKEND_DOMAIN from "../../../service";

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

class ProfileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            profileImage: null,
            username: null,
        };
        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        try {
            // Fetch user details from the API
            const response = await fetch(`${BACKEND_DOMAIN}/business/merchant/detail`, {
                headers: {
                    Authorization: `Bearer ${
                        localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).token : ""
                    }`,
                },
            });

            if (!response.ok) {
                throw new Error("Error fetching user details");
            }

            const userData = await response.json();
            const unformattedName = userData.name;
            const username = capitalizeFirstLetter(unformattedName);

            this.setState({
                username,
                profileImage: userData.profile_image,
            });
        } catch (error) {
            console.error("Error fetching user details:", error.message);
        }
    }

    toggle() {
        this.setState((prevState) => ({
            menu: !prevState.menu,
        }));
    }

    handleLogout = () => {
        this.props.router.navigate("/route-money/merchant/login");
    };

    handleProfile = () => {
        this.props.router.navigate("/route-money/merchant/profile");
    };

    render() {
        const { username, profileImage } = this.state;

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
                    <DropdownToggle
                        tag="button"
                        className="btn header-item waves-effect"
                        id="page-header-user-dropdown"
                    >
                        <img
                            className="rounded-circle header-profile-user me-1"
                            style={{ objectFit: "cover" }}
                            src={profileImage}
                            alt=""
                        />
                        <span className="d-none d-xl-inline-block ms-1">
                            <strong>{username}</strong>
                        </span>
                        <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem onClick={this.handleProfile}>
                            <i className="ri-user-line align-middle me-1"></i> Profile
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem className="text-danger" onClick={this.handleLogout}>
                            <i className="ri-shut-down-line align-middle me-1 text-danger"></i> Logout
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export default withRouter(ProfileMenu);
