import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import TableContainer from "../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Container } from "reactstrap";
import axios from "axios";
import convertDate from "../../Orders/utilities/dateFormatter";
import BACKEND_DOMAIN from "../../../service";

const Activities = () => {
    const authToken = useSelector((state) => state.Login.token);
    const [logs, setLogs] = useState([]);
    const [count, setCount] = useState(0);

    const fetchLogs = async (token) => {
        const apiUrl = `${BACKEND_DOMAIN}/business/merchant/activity/logs`;
        //https://backoffice.route.money/api/v1/business/merchant/activity/logs/

        try {
            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            setLogs(response.data.data);
        } catch (e) {
            console.error("Error fetching logs", e);
        }
    };

    useEffect(() => {
        fetchLogs(authToken);
    }, [authToken]);

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "user_info.first_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "User Name",
                accessor: "user_info.username",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Activity Description",
                accessor: "description",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "IP Address",
                accessor: "ip",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date",
                accessor: "date",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }) => {
                    let modifiedDate = convertDate(value);
                    return <React.Fragment>{modifiedDate}</React.Fragment>;
                },
            },
        ],
        []
    );

    const breadcrumbItems = [
        { title: "Users", link: "/" },
        { title: "Activity Log", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Activity Log" breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={logs || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Activities;
