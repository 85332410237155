import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Container, Modal, ModalHeader, ModalBody, ModalFooter, Alert,Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import BACKEND_DOMAIN from "../../../../service";
import '../../utilities/Display.css';


// import branch recent activities
import MiniWidgets from "../../../Dashboard/Branch/MiniWidgets";
import RevenueAnalytics from "../../../Dashboard/Branch/RevenueAnalytics";
import SalesAnalytics from "../../../Dashboard/Branch/SalesAnalytics";
import EarningReports from "../../../Dashboard/Branch/EarningReports";
import LatestTransactions from "../../../Dashboard/Branch/LatestTransactions";
import RecentOrders from "../../../Dashboard/Branch/RecentOrders";
import {
    fetchProductsOrdersAndBranches,
    fetchProductOffersData,
    fetchCashbackOffersData,
    
} from "../../../Dashboard/Branch/data_fetching/dashboardData";

const MerchantBranchList = () => {
    const [productsCount, setProductsCount] = useState(0);
    const [branchesCount, setBranchesCount] = useState(0);
    const [ordersCount, setOrdersCount] = useState(0);
    const [productOffers, setProductOffers] = useState(0);
    const [cashbackOffers, setCashbackOffers] = useState(0);
    const [walletBalance, setWalletBalance] = useState(0);



    const [branches, setBranches] = useState([]);
    const [noBranches, setNoBranches] = useState(false);

    const authToken = useSelector((state) => state.Login.token);

    const [deleteModal, setDeleteModal] = useState(false);
    const [branchIdToDelete, setBranchIdToDelete] = useState(null);
    const [deletionSuccessMessage, setDeletionSuccessMessage] = useState("");

    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    // display a branch on modal
    const [branchModal, setBranchModal] = useState(false);
    const [branchIdToDisplay, setBranchIdToDisplay] = useState(null);
    const [selectedBranch, setSelectedBranch]=useState(null);
    //const [deletionSuccessMessage, setDeletionSuccessMessage] = useState("");

    const toggleDisplayModal = () => setBranchModal(!branchModal);


    // fetch branch dashboard data
    const setDashboardInfo = async (token) => {
        try {
            const [
                dashboardData,
                productOffersCount,
                cashbackOffersCount,
                walletBalanceData
            ] = await Promise.all([
                fetchProductsOrdersAndBranches(token),
                fetchProductOffersData(token),
                fetchCashbackOffersData(token),
              
            ]);

           // setProductsCount(dashboardData.cards_data.products_count);
            setBranchesCount(dashboardData.cards_data.branches_count);
          //  setOrdersCount(dashboardData.cards_data.orders_count);

            setProductOffers(productOffersCount);
            setCashbackOffers(cashbackOffersCount);
           // setWalletBalance(walletBalanceData.ledger_balance); // Ensure correct access to ledger_balance
        } catch (e) {
            console.error("Failed to fetch dashboard data ->", e);
        }
    };

    useEffect(() => {
        setDashboardInfo(authToken);
    }, [authToken]);


    // fetch wallet balance
// const branchWalletApi=`https://backoffice.route.money/api/v1/wallets/merchant/branch//balance/-O-lSAgJGFYGeOyOX8IB`;

const fetchWalletBalanceData = async (authToken, branchId) => {
    const branchWalletApi = `https://backoffice.route.money/api/v1/wallets/merchant/branch/balance/${branchId}`;
   console.log('branch balance api ',branchWalletApi);
    try {
        const response = await axios.get(branchWalletApi, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        console.log('branch balance is ',response.data.wallet);
        setWalletBalance(response.data.data.wallet.ledger_balance); // Ensure correct access to nested data
    } catch (e) {
        console.error("Failed to fetch wallet balance -> ", e);
        throw e; // Throw error to indicate failure
    }
};

// product and order count


const fetchProductsOrderData = async (authToken, branchId) => {
  
    const productOrdrerApi=`https://backoffice.route.money/api/v1/business/merchant/branch/counts/${branchId}`;

    console.log('branch order api ',productOrdrerApi);
    try {
        const response = await axios.get(productOrdrerApi, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        console.log('branch balance is ',response.data);
        setProductsCount(response.data.product_count); // Ensure correct access to nested data
        setOrdersCount(response.data.order_count);
    } catch (e) {
        console.error("Failed to fetch wallet balance -> ", e);
        throw e; // Throw error to indicate failure
    }
};

// fetch recent transactions

// const recentOrders = async (authToken, branchId) => {

//     const recentTransactions=`https://backoffice.route.money/api/v1/business/merchant/branch/transactions/${branchId}/`;
//     // try {
//     //     const response = await axios.get(recentTransactions, {
//     //         headers: {
//     //             Authorization: `Bearer ${authToken}`,
//     //             "Content-Type": "application/json",
//     //         },
//     //     });

//     //     return response.data;
//     // } catch (e) {
//     //     console.error("Failed to fetch orders", e);
//     // }

//     fetch(recentTransactions, {
//         headers: {
//             Authorization: `Bearer ${authToken}`,
//         },
//     })
//         .then((response) => {
//             if (!response.ok) {
//                 throw new Error(`HTTP error! Status: ${response.status}`);
//             }
//             return response.json();
//         })
//         .then((res) => {
//             let data;
          
//             if (data.length === 0) {
//                 setOrderListIsEmpty(true);
//             }
//             setOrders(data.reverse());
//         })
//         .catch((error) => {
//             console.error("Error fetching orders:", error);
//         });
// };

//    useEffect(() => {
//     fetchWalletBalanceData(authToken);
// }, []);

    const businessBranchesReports = [
        {
            icon: "ri-currency-line",
            title: "Cumulative Wallet Balance",
            value: `KES 0`,
            rate: "Create pin",
            desc: "From previous month",
            routeTo: "#",
        },
        {
            icon: "ri-shopping-bag-2-line",
            title: "Number of Products",
            value: '0',
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/branch/products",
        },
        {
            icon: "ri-shopping-cart-2-line",
            title: "Total Orders",
            value: '0',
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/branch/order-history",
        },
    ];


    const businessPartnerReports = [
        {
            icon: "ri-currency-line",
            title: "Wallet Balance",
            value: `KES 0`,
            rate: "Create pin",
            desc: "From previous month",
            routeTo: "#",
        },
        {
            icon: "ri-shopping-bag-2-line",
            title: "Number of Products",
            value: 0,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/branch/products",
        },
        {
            icon: "ri-shopping-cart-2-line",
            title: "Total Orders",
            value: 0,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/branch/order-history",
        },
    ];

    const breadcrumbBranchItems = [
        { title: "Switch Account", link: "#"},
        { title: "Dashboard", link: "#" },
    ];

    const deleteBranch = (branchId) => {
        setBranchIdToDelete(branchId);
        toggleDeleteModal();
    };

    const openBranchModal = (branchId) => {
        const selected = branches.find((branch) => branch.id === branchId);

        setSelectedBranch(selected);
        fetchWalletBalanceData(authToken, selected.creator);
        fetchProductsOrderData(authToken, selected.creator);
        toggleDisplayModal();
    };

    // Modified function to open the branch modal and fetch wallet balance
// const openBranchModal = (branchId) => {
//     const selected = branches.find((branch) => branch.id === branchId);
//     setSelectedBranch(selected);
    
//     // Fetch wallet balance when a branch is selected
//     if (selected) {
//         fetchWalletBalanceData(authToken, selected.id);
//         console.log('Selected branch is ', selected.id);
//     }
    
//     toggleDisplayModal();
// };


    //console.log("display branches ");
    const confirmDelete = () => {
        if (branchIdToDelete) {
            const branchDeletionAPIUrl = `${BACKEND_DOMAIN}/business/merchant/branch/delete/${branchIdToDelete}`;

            fetch(branchDeletionAPIUrl, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.ok) {
                        const updatedBranches = branches.filter((branch) => branch.id !== branchIdToDelete);
                        setBranches(updatedBranches);
                        setDeletionSuccessMessage("Branch deleted successfully!");
                        setTimeout(() => {
                            setDeletionSuccessMessage("");
                        }, 3000);
                    } else {
                        console.error("Error deleting branch: ", response.status);
                    }
                })
                .catch((error) => console.error("Error deleting branch: ", error))
                .finally(() => {
                    setBranchIdToDelete(null);
                    toggleDeleteModal();
                });
        }
    };

    const apiUrlBranch = `${BACKEND_DOMAIN}/business/merchant/branch/list`;

    const fetchData = async (token) => {
        try {
            const response = await axios.get(apiUrlBranch, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            if (response.data.data.length === 0) {
                setNoBranches(true);
            }
            setBranches(response.data.data);
        } catch (e) {
            console.log("Error fetching branches -> ", e);
        }
    };

    useEffect(() => {
        fetchData(authToken);
    }, [authToken]);

    const columns = useMemo(() => [
        {
            Header: "Branch Name",
            accessor: "name",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Location",
            accessor: "location",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Contact Person",
            accessor:"contact_person",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Email",
            accessor: "email",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Phone Numnber",
            accessor: "phone_number",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Action",
            accessor: (cellProps) => {
                const branchId = cellProps.id;

                return (
                    <React.Fragment>
                           <Link to="#" className="me-3 text-primary" onClick={() => openBranchModal(branchId)}>
                                    <i className="mdi mdi-eye font-size-18"></i>
                                </Link>
                        {/* <Link to={`/route-money/merchant/branch/edit/${branchId}`} className="me-3 text-secondary">
                            <i className="mdi mdi-pencil font-size-18"></i>
                        </Link> */}
                        <Link to="#" className="me-3 text-danger" onClick={() => deleteBranch(branchId)}>
                            <i className="mdi mdi-delete font-size-18"></i>
                        </Link>
                    </React.Fragment>
                );
            },
            disableFilters: true,
            filterable: false,
        },
    ]);

    const breadcrumbItems = [
        { title: "Branches", link: "/" },
        { title: "Branch List", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Branch List" breadcrumbItems={breadcrumbItems} />
                    <Link to="/route-money/merchant/branch/add" className="btn btn-primary mb-3">
                        Add Branch
                    </Link>

                    <Row>
               <Col xl={12}>
              <Row>
                <MiniWidgets reports={businessBranchesReports} />
              </Row>
            </Col>
          </Row>

                    {deletionSuccessMessage && <Alert color="success">{deletionSuccessMessage}</Alert>}
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={branches || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={noBranches}
                                isEmptyContent="No branches have been added yet."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete this branch?</ModalBody>

                <ModalFooter>
                    <Button color="secondary" onClick={toggleDeleteModal}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={confirmDelete}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Branch summary modal */}
            <Modal
            isOpen={branchModal}
            role="document"
            autoFocus={true}
            centered={true}
            fullscreen={true}// or set to true for full screen on all breakpoints
            className="composemodal"
            toggle={toggleDisplayModal}
            >
  <div className="modal-content">
<ModalHeader className="position-relative d-flex justify-content-between align-items-center">
  <span>
    Branch {selectedBranch && selectedBranch.name}
  </span>
  <span style={{ position: "absolute", right: 0 }}
                            className={` p-2 me-3`}
                        >
                          switch
                        </span>
</ModalHeader>



    <ModalBody style={{ overflowY: "auto" }}>
      {selectedBranch && (
        <>
          <Row>
            <Col xl={12}>
              <Row>
                <MiniWidgets reports={businessPartnerReports} />
              </Row>
            </Col>
          </Row>
          <Row>
            <LatestTransactions />
            <RecentOrders />
          </Row>
        </>
      )}
    </ModalBody>

    <ModalFooter>
      <Button color="secondary" onClick={toggleDisplayModal}>
        Cancel
      </Button>
    </ModalFooter>
  </div>
</Modal>

        </React.Fragment>
    );
};

export default MerchantBranchList;
